export const updateDay = ({
    commit, getters,
}, payload) => {
    const dayInput = `${getters.moduleId}_day`;
    commit('setAttribute', {
        id: dayInput,
        attribute: 'value',
        value: payload,
    }, {
        root: true,
    });
};

export const updateMonth = ({
    commit, getters,
}, payload) => {
    const monthInput = `${getters.moduleId}_month`;
    commit('setAttribute', {
        id: monthInput,
        attribute: 'value',
        value: payload,
    }, {
        root: true,
    });
};


export const updateYear = ({
    commit, getters,
}, payload) => {
    const yearInput = `${getters.moduleId}_year`;
    commit('setAttribute', {
        id: yearInput,
        attribute: 'value',
        value: payload,
    }, {
        root: true,
    });
};