<template>
    <div class="lni-c-search__search lni-u-full-width lni-u-overflow--hidden lni-u-flex">
        <label
            :for="`${id}_search-text`"
            class="lni-u-visually-hidden">{{ labelText }}</label>
        <input
            :id="`${id}_search-text`"
            ref="searchInput"
            class="lni-c-search__input lni-u-full-width"
            :placeholder="placeholder"
            :maxlength="maxlength"
            :value="query"
            :name="name"
            type="search"
            :autocomplete="autocomplete"
            @keyup.enter.prevent="onEnter()"
            @keydown="$emit('keydown', $event)"
            @input="onInput"
            @blur="onBlur"
            @focus="onFocus"
            @paste="onPaste">
        <input
            v-if="sendPagerParameter"
            type="hidden"
            name="pg"
            value="1">
        <button
            v-show="query && query.length > 0"
            type="button"
            aria-label="Clear Search"
            class="lni-c-search__clear-button"
            @click="clearSearch()">
            <span
                class="lnicon--close --close"
                aria-hidden="true"></span>
        </button>
        <button
            ref="searchSub"
            type="button"
            class="lni-c-search__search-button"
            aria-label="Search"
            :disabled="query.length < 1 && emptySearchAction.length < 1"
            @click="onEnter()">
            <span
                :aria-hidden="true"
                class="lnicon--search"></span>
        </button>
    </div>
</template>

<script>

export default {
    name: 'lni-input-search',
    methods: {
        clearSearch() {
            this.query = '';
            this.dispatchEvent('clearAction');
        },
        onInput($event) {
            // restrict input to max length
            if ($event.target.value && this.maxlength) {
                const maxLengthNum = parseInt(this.maxlength);
                const valueString = $event.target.value.toString();
                if (!isNaN(maxLengthNum) && valueString.length > maxLengthNum) {
                    $event.target.value = this.query;
                    return;
                }
            }

            this.query = $event.target.value;
            this.dispatchEvent('inputAction', $event);
        },
        onEnter() {
            if (this.query) {
                this.dispatchEvent('searchAction');
            } else {
                this.dispatchEvent('emptySearchAction');
            }
        },
        onBlur($event) {
            this.dispatchEvent('blurAction');
            this.$emit('blur', $event);
        },
        onFocus() {
            this.dispatchEvent('focusAction');
            this.$emit('focus');
        },
        onPaste() {
            this.dispatchEvent('pasteAction');
            this.$emit('paste');
        },
    },
}; </script>