<template>
    <lni-input-text
        :id="`${id}_input-text`"
        ref="textarea"
        v-model="value"
        :labelText="labelText"
        :inputAction="[`${id}/updateValue`, inputAction]"
        :required="required"
        :disabled="disabled"
        :aria-describedby="describedBy"
        class="lni-c-textarea"
        formElement="textarea"
        :filled="filled"
        :fullWidth="fullWidth"
        :maxlength="limit"
        :labelBefore="labelBefore"
        :inputValidationAction="inputValidationAction"
        :changeValidationAction="changeValidationAction"
        :blurValidationAction="blurValidationAction"
        @input="updateValue"
        @updated="updateValidity"
        @valueSet="updateValidity">
        <div
            v-if="!!limit"
            :id="`${id}__character-count`"
            slot="suffix"
            :aria-live="counterAriaLiveState"
            :aria-atomic="counterAriaAtomicState"
            class="lni-c-textarea__character-count">
            {{ chars }}
            <span class="lni-u-visually-hidden"> of </span>
            <span aria-hidden="true">/</span>
            {{ limit }}
        </div>
        <slot></slot>
        <slot
            slot="helperText"
            name="helperText"></slot>
    </lni-input-text>
</template>

<script>
export default {
    name: 'lni-input-textarea',
    data() {
        return {
            contentHeight: null,
        };
    },
    computed: {
        chars() {
            return this.$store.getters[`${this.id}/length`];
        },
        charsLeft() {
            return this.$store.getters[`${this.id}/remaining`];
        },
        hasHelperText() {
            return !!this.$slots.helperText;
        },
        /**
         * Sets the value of aria-live attribute
         * Sets whether changes to character counter are read at all
         * @returns {(String|null)};
         **/
        counterAriaLiveState() {
            const assertive = 20;
            const polite = 70;
            if ( this.charsLeft <= polite && this.charsLeft > assertive) {
                return 'polite';
            }
            if ( this.charsLeft <= assertive ) {
                return 'assertive';
            }
            return null;
        },

        /**
         * Sets the value of aria-atomic attribute
         * Sets whether every change to character counter is read
         * @returns {(String|null)};
         **/
        counterAriaAtomicState() {
            const atomicFirstTrue = 70;
            const atomicFalse = 60;
            const atomicSecondTrue = 20;

            if (this.charsLeft > atomicFirstTrue ) {
                // more than 70 chars left
                return null;
            } else if ( this.charsLeft <= atomicFalse && this.charsLeft > atomicSecondTrue ) {
                // between 60 and 20 chars left
                return 'false';
            }
            // less than 20 chars
            return 'true';
        },
        describedBy() {
            let ids = `${this.id}_character-count`;
            if (this.hasHelperText) {
                ids += ` ${this.id}_helper-text`;
            }
            return ids;
        },
        textarea() {
            return this.$refs.textarea.$el.querySelector('textarea');
        },
    },
    mounted() {
        this.$watch('value', function updateState() {
            this.$store.dispatch(`${this.id}/updateTextInputValue`, {
                componentId: this.id,
            });
        });

        this.updateValidity();
    },
    updated() {
        //TODO: create smoother transition
        // see https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
        // but adapt for scroll height
        // or smoothly transition the height property with JS and math
        this.contentHeight = this.getScrollHeight();
        this.textarea.style.setProperty('height', `${this.contentHeight}px`);
    },
    methods: {
        updateValue() {
            this.$store.dispatch(`${this.id}/updateValue`, {
                sourceId: this.id,
                targetId: `${this.id}_input-text`,
            });
        },
        updateValidity() {
            const validity = {
                ...this.$refs.textarea.$refs.input.validity,
            };
            if (this.limit && this.limit > 0) {
                validity.tooLong = this.charsLeft < 0;
            }
            //Set lni-input-text validity
            return this.$store.dispatch('updateValidity', {
                targetId: `${this.id}_input-text`,
                validity,
            });
        },
        getScrollHeight() {
            this.textarea.style.setProperty('height', 'auto');
            return this.textarea.scrollHeight;
        },
    },
}; </script>