<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        :id="`${id}`"
        :class="classNames">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'lni-intersection-observer',
    data() {
        return {
            observer: null,
        };
    },
    beforeDestroy() {
        if (this.observer) {
            this.unobserve();
            this.observer = null;
        }
    },
    mounted() {
        if (typeof IntersectionObserver === 'undefined') {
            this.$emit('intersectionObserverChange', {
                isIntersecting: true,
            }, this.intersectionObserverNotSupported);
        } else {
            this.observer = new IntersectionObserver(entries => {
                this.$emit('intersectionObserverChange', entries[0], this.unobserve);
            }, {
                rootMargin: this.rootMargin,
                root: this.selector ? document.querySelector(this.selector) : null,
                threshold: this.threshold,
            });
            this.observer.observe(this.$el);
        }

    },
    methods: {
        unobserve() {
            this.observer.unobserve(this.$el);
        },
        intersectionObserverNotSupported() {
            console.warn('IntersectionObserver API is not available in your browser');
        },
    },
}; </script>