<template>
    <fieldset
        :id="id"
        :class="classString">
        <legend :class="legendClass">
            {{ legend }}
        </legend>
        <slot></slot>
    </fieldset>
</template>

<script>/**
 *  The lni-fieldset component.
 *  @module components/lni-fieldset
 */


/**
 * The lni-fieldset is a fieldset.
 */

export default {
    name: 'lni-fieldset',

}; </script>