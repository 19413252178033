export default function scrollToTop(context, payload) {
    if (payload) {
        const el = document.getElementById(context.getters.moduleId);

        el.scrollIntoView({
            block: 'start',
            inline: 'nearest',
            behavior: 'smooth',
        });
    }
}