<template>
    <!-- eslint-disable vue/no-deprecated-dollar-listeners-api -
     TODO: once we upgrade events will be included in $attrs -->
    <vue-recaptcha
        v-bind="$attrs"
        ref="recaptcha"
        :class="classString"
        sitekey="6LdlHR8UAAAAAPU3zBKQujd7qTEnPa3sPt9LbPv_"
        :size="size"
        v-on="$listeners"
        @verify="onVerify"
        @expired="onExpired"
    />
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
    name: 'LniRecaptcha',
    components: {
        'vue-recaptcha': VueRecaptcha,
    },
    mounted() {
        this.$watch('forceReset', force => {
            if (force) {
                this.reset();
                this.forceReset = false;
            }
        });
    },
    methods: {
        onVerify() {
            this.dispatchEvent('verifyAction');
        },
        onExpired() {
            this.reset();
            this.dispatchEvent('expireAction');
        },
        reset() {
            this.$refs.recaptcha.reset();
        },
    },
}; </script>