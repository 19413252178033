export const updatePhoneExtension = (context, payload) => {
    if (context.rootState[payload.targetId]) {
        context.commit('setAttribute', {
            id: payload.sourceId,
            attribute: 'phoneExtension',
            value: context.rootState[payload.targetId].value,
        },
        {
            root: true,
        });
    }
};

export const updatePhoneNumber = (context, payload) => {
    context.commit('setAttribute', {
        id: payload.sourceId,
        attribute: 'phoneNumber',
        value: context.rootState[payload.targetId].value,
    },
    {
        root: true,
    });
};