/* eslint-disable max-len */
import monthCustomValidation from '@gov.wa.lni/component.lni-input-date/actions/monthCustomValidation.js';
import dayCustomValidation from '@gov.wa.lni/component.lni-input-date/actions/dayCustomValidation.js';
import yearCustomValidation from '@gov.wa.lni/component.lni-input-date/actions/yearCustomValidation.js';
import customValidate from '@gov.wa.lni/component.lni-input-date/actions/customValidate.js';

export default {
    monthCustomValidation,
    dayCustomValidation,
    yearCustomValidation,
    customValidate,
};