
const setAttribute = (attribute, context, payload) => {
    context.commit('setAttribute', {
        id: payload.sourceId,
        attribute,
        value: context.rootState[payload.targetId][attribute],
    }, {
        root: true,
    });
};

export default function updateEmail(context, payload) {
    setAttribute('value', context, payload);
}