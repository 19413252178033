<template>
    <span
        class="lni-c-english-only-link"
        :title="isEnglish ? null : text.englishOnly"
    >
        <slot @click.prevent="onClick" />
        <span
            v-if="!isEnglish"
            class="lni-c-english-only-link__icon lnicon--warning lni-u-type--xxs lni-u-link-style"
            @click.prevent="onClick"
        />
        <lni-modal
            :id="`${id}_modal`"
            :title="text.title"
            :compact="true"
            :hideCloseButton="true"
            allowClose="true"
        >
            <template slot="content">
                <p class="lni-u-pr2">
                    <lni-html-element
                        :id="`${id}_callUsMessage`"
                        element="p"
                        :htmlString="message"
                        class="lni-u-pr2"
                    />
                </p>
                <form>
                    <lni-checkbox
                        :id="`${id}_dismiss`"
                        class="lni-u-mt2 lni-u-type--xs"
                        :changeAction="`${id}/dismissWarnings`"
                        :labelText="text.dismissWarning"
                        :hasInlineMessages="false"
                    />
                </form>
            </template>
            <template slot="actions">
                <div class="lni-c-english-only__actions lni-u-flex lni-u-flex-wrap lni-u-justify-end">
                    <lni-button
                        :id="`${id}_closeButton`"
                        class="lni-c-button --text"
                        :text="text.exit"
                        @click="toggleModal(false)"
                    />
                    <a
                        :href="href"
                        class="lni-c-english-only-link__modal-link lni-u-text--all-caps lni-u-type--bold lni-c-button"
                        @click="toggleModal(false)"
                    >
                        {{ text.continueToContent }}
                        <span
                            class="lnicon--arrow--right"
                            aria-hidden="true"
                        />
                    </a>
                </div>
            </template>
        </lni-modal>
    </span>
</template>

<script>
import {
    getText,
} from '@gov.wa.lni/component.lni-english-only-link/services/text.api.js';
import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';

export default {
    name: 'LniEnglishOnlyLink',
    data() {
        return {
            href: '',
        };
    },
    computed: {
        // TODO: pass html from two Content (Generic) for the message.
        message() {
            if (!this.text) {
                return '';
            }
            const messageTemplate = this.text[`${this.type}Message`];
            return interpolate(messageTemplate, {
                directoryLink: this.directoryLink,
            });
        },
        directoryLink() {
            if (!this.text) {
                return '';
            }
            return `<a href="${this.text.directoryLinkUrl}">${this.text.directoryLinkText}</a>`;
        },
        isEnglish() {
            return this.$oneLni.getLanguage() === 'en';
        },
    },
    mounted() {
        this.getText();
        const isDismissed = this.$oneLni.storage.session.load(this.cookieName);
        if (!isDismissed && !this.isEnglish) {
            const slotElement =  this.$slots.default[0].elm;
            if (slotElement.nodeName === 'A') {
                this.href = slotElement.href;
                slotElement.addEventListener('click', this.onClick);
            } else {
                console.warn('An "a" element not detected as the lone slot content. This component requires only a link as it\'s slot content.');
            }
        }
    },
    methods: {
        onClick(event) {
            event.preventDefault();
            const cookie = this.$oneLni.storage.session.load(this.cookieName);
            if (!cookie || (cookie && !cookie.isDismissed)) {
                this.toggleModal(true);
            }
        },
        toggleModal(isVisible) {
            this.$store.commit('setAttribute', {
                id: `${this.id}_modal`,
                attribute: 'visible',
                value: isVisible,
            });

            if (isVisible) {
                this.$store.commit('setAttribute', {
                    id: `${this.id}_dismiss`,
                    attribute: 'checked',
                    value: false,
                });
            }
        },
        async getText() {
            const text = await getText('englishOnlyLink', this.$oneLni);
            this.text = text;

            this.setTemplateText();
        },
        setTemplateText() {
            this.$store.commit('setAttribute', {
                id: `${this.id}_closeButton`,
                attribute: 'text',
                value: this.text.exit,
            });
            this.$store.commit('setAttribute', {
                id: `${this.id}_dismiss`,
                attribute: 'labelText',
                value: this.text.dismissWarning,
            });
            this.$store.commit('setAttribute', {
                id: `${this.id}_callUsMessage`,
                attribute: 'htmlString',
                value: this.message,
            });
        },
    },
}; </script>