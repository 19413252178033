import text from '@gov.wa.lni/framework.one-lni/source/locale/en.js';
import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';

export default () => ({
    state: {
        options: [],
        classString: '',
        inputValue: '',
        value: '',
        labelText: '',
        changeAction: '',
        labelBefore: false,
        displayCount: false,
        selectAllLabel: null,
        required: false,
        customWidth: false,
        disabled: false,
        dataSet: [],
        inline: true,
        dense: false,
        filled: true,
        ariaDescribedby: null,
        placeholder: '',
        isOpen: false,
        keepOpen: false,
        messages: {
            valueMissing: {
                global: state => interpolate(text['lni-multiselect'].messages.valueMissing.global, {
                    labelText: state.labelText,
                    legend: state.legend,
                }),
                inline: () => text['lni-multiselect'].messages.valueMissing.inline,
            },
        },

        hasInlineMessages: true,
        errorText: '',
        errors: [],
        validity: null,
        flags: {
            dirty: false,
            touched: false,
        },
        changeValidationAction: 'validate',
    },
});