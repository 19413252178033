<template>
    <div
        :id="id"
        @focus="focusOnInput">
        <lni-input-text
            :id="`${id}_input-text`"
            ref="input"
            :changeAction="changeAction"
            :inputAction="[`${id}/updateEmail`, inputAction]"
            type="email"
            :labelText="labelText"
            :value="value"
            :filled="filled"
            :dense="dense"
            leadingIcon="envelope"
            autocomplete="email"
            :required="required"
            :hasInlineMessages="hasInlineMessages"
            :minlength="minlength"
            :maxlength="maxlength"
            :inputValidationAction="inputValidationAction"
            :changeValidationAction="changeValidationAction"
            :blurValidationAction="blurValidationAction"
            :labelBefore="labelBefore"
            :fullWidth="fullWidth"
            :rtl="rtl">
            <slot
                slot="helperText"
                name="helperText"></slot>
        </lni-input-text>
    </div>
</template>

<script>
/**
*  The lni-input-email component.
*  @module components/lni-input-email
*/

/**
* The lni-input-text is a basic email input control with a label.
* It can also display validation messages.
*/
export default {
    name: 'lni-input-email',

    mounted() {
        this.$watch('value', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                this.$store.commit('setAttribute', {
                    id: this.id + '_input-text',
                    attribute: 'value',
                    value: newValue,
                });
            }
        });
    },
    methods: {
        focusOnInput() {
            this.$refs.input.$refs.input.focus();
        },
    },
}; </script>