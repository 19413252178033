<template>
    <fieldset
        :id="id"
        :class="{ '--required': required }"
        class="lni-c-checkbox-list"
        @focus="focusOnFirstInput">
        <legend
            v-if="labelText"
            class="lni-c-checkbox-list__legend lni-u-line-height--tight">
            {{ labelText }}
        </legend>
        <div
            v-if="hasInlineMessages || hasHelperText"
            :id="`${id}_helper-text`"
            :class="hasErrorText ? 'lni-c-fieldset__error-message' : 'lni-c-fieldset__helper-text'"
            class="lni-c-contextual-help__wrapper lni-u-type--xxs lni-u-line-height--tight">
            <template v-if="hasErrorText">
                <span
                    class="lni-u-font-color--error"
                    role="alert">
                    <span
                        aria-hidden="true"
                        class="lnicon--exclamation"></span>
                    {{ errorText }}
                </span>
            </template>
            <template v-if="!hasErrorText">
                <slot name="helperText"></slot>
            </template>
        </div>
        <div
            role="group"
            :class="{
                'lni-u-flex lni-u-flex-wrap lni-u-cb': !isColumn,
                '--column': isColumn,
            }">
            <div
                v-if="selectAllLabel"
                class="lni-c-checkbox">
                <input
                    :id="`${id}_All`"
                    :class="{'--hasFocus': hasKeyboardFocus && focusedCheckbox === 'All'}"
                    value="All"
                    class="lni-c-checkbox__input"
                    type="checkbox"
                    @change="onChange($event)"
                    @keyup="showKeyboardFocus('All')"
                    @blur="hasKeyboardFocus = false">
                <label
                    :for="id + '_All'"
                    class="lni-c-checkbox__label">
                    <span class="lni-c-checkbox__indicator"></span>
                    <span class="lni-c-checkbox__label-text">{{ selectAllLabel }}
                        <span v-if="displayCount">({{ totalCount || 0 }})</span>
                    </span>
                </label>
            </div>

            <div
                v-for="option in options"
                :key="id + '_' + option.value"
                :class="['lni-c-checkbox', {'--disabled': option.disabled || (displayCount && (!option.count || option.count == 0))}]">
                <input
                    :id="`${id}_${option.value}`"
                    :ref="`${id}_${option.value}`"
                    :class="{'--hasFocus': hasKeyboardFocus && focusedCheckbox === option.value}"
                    :value="option.value"
                    class="lni-c-checkbox__input"
                    type="checkbox"
                    :disabled="option.disabled || (displayCount && (!option.count || option.count == 0))"
                    @change="onChange($event)"
                    @keyup="showKeyboardFocus(option.value)"
                    @blur="hasKeyboardFocus = false">
                <label
                    :for="id + '_' + option.value"
                    class="lni-c-checkbox__label">
                    <span class="lni-c-checkbox__indicator"></span>
                    <span class="lni-c-checkbox__label-text">{{ option.text }}
                        <span v-if="displayCount">({{ option.count || 0 }})</span>
                    </span>
                </label>
                <p
                    v-if="option.description"
                    class="lni-c-checkbox__description">
                    {{ option.description }}
                </p>
            </div>
        </div>
    </fieldset>
</template>

<script>
//TODO: ACW Internal error handling.

export default {
    name: 'lni-checkbox-list',

    data() {
        return {
            focusedCheckbox: null,
            hasKeyboardFocus: false,
        };
    },
    computed: {
        totalCount() {
            return this.options.reduce( (acc, obj) => {
                if (typeof obj.count === 'number') {
                    return acc + obj.count;
                }
                return acc;
            }, 0);
        },
        hasErrorText() {
            return !!this.$store.state[this.id].errorText;
        },
        hasHelperText() {
            return !!this.$slots.helperText;
        },

    },
    mounted() {
        this.updateValidity();
        this.$watch('value', () => {
            this.setValuesOnInputs();
            this.updateValidity();
        });
        window.addEventListener('resize', this.handleResize);

    },
    updated() {
        this.setValuesOnInputs();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            const mobileBreakPoint = '400px';
            const mq = window.matchMedia(`(max-width: ${mobileBreakPoint})`);
            if (mq.matches || this.isColumn) {
                this.$store.commit('setAttribute', {
                    id: this.id,
                    attribute: 'isColumn',
                    value: true,
                });
            } else {
                this.$store.commit('setAttribute', {
                    id: this.id,
                    attribute: 'isColumn',
                    value: false,
                });
            }
        },
        setValuesOnInputs() {
            const refKeys = Object.keys(this.$refs);

            // In this case, the component has been removed and there is
            // no DOM for the values to be set upon.
            if (refKeys.length === 0) {
                return;
            }

            refKeys.forEach(key => {
                this.$refs[key][0].checked = false;
            });

            if (this.value && this.value.length > 0) {
                this.value.forEach(value => {
                    let checkbox = this.$refs[`${this.id}_${value}`];
                    if (checkbox && checkbox.length > 0) {
                        checkbox[0].checked = true;
                        checkbox[0].disabled = false;
                        checkbox[0].classList.remove('--disabled');
                    }
                });
            }
        },
        showKeyboardFocus(ref) {
            this.focusedCheckbox = ref;
            this.hasKeyboardFocus = true;
        },

        onChange($event) {
            if ($event.target.value === 'All') {
                if ($event.target.checked) {
                    this.value = Object.keys(this.$refs)
                        .map(key => this.$refs[key][0])
                        .filter(element => !element.disabled)
                        .map(element => element.value);
                } else {
                    this.value = null;
                }

            } else {
                this.value = Object.keys(this.$refs)
                    .map(key => this.$refs[key][0])
                    .filter(element => element.checked)
                    .map(element => element.value);
            }

            this.$store.commit(`${this.id}/setDirty`, {
                value: true,
            });
            this.updateValidity().then(() => {
                if (this.$store.state[this.id].flags.dirty && this.changeValidationAction) {
                    this.dispatchEvent('changeValidationAction');
                }

                const response = this.dispatchEvent('changeAction');
                if (response && response.then) {
                    response.then(() => {
                        this.$emit('change');
                    });
                } else {
                    this.$emit('change');
                }
            });
        },
        updateValidity() {
            return this.$store.dispatch(`updateValidity`, {
                targetId: this.id,
            });
        },
        focusOnFirstInput() {
            this.hasKeyboardFocus = true;
            this.focusedCheckbox = this.options[0].value;
            let el = this.$el.querySelector('input');
            this.$nextTick(() => el.focus());
        },
    },
}; </script>