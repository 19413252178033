<template>
    <div
        class="lni-c-notification lni-u-pa2 lni-u-ba"
        :class="`--${type}`"
        :role="isDynamic ? 'status' : null"
        :aria-live="isDynamic ? 'polite' : null"
    >
        <slot />
    </div>
</template>
<script>

export default {
    name: 'LniNotification'
}; </script>