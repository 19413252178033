<template>
    <div
        class="lni-c-disclosure"
        :class="classString"
        :data-open="isOpen"
    >
        <component
            :is="toggleWrap"
            v-if="toggleWrap"
        >
            <button
                class="lni-c-disclosure__toggle"
                :aria-controls="`${id}_content`"
                :aria-expanded="isOpen ? 'true' : 'false'"
                @click="toggle"
            >
                <div
                    v-if="leadingIndicator"
                    class="lni-c-disclosure__indicator"
                >
                    <slot
                        v-if="hasIndicatorSlot"
                        name="indicator"
                    />
                    <svg
                        v-else
                        width="20"
                        height="20"
                        viewBox="-8 0 28 20"
                    >
                        <path d="M0 19.924l12-9.697L0 .53v19.394z" />
                    </svg>
                </div>
                <slot name="toggle" />
                <div
                    v-if="!leadingIndicator"
                    class="lni-c-disclosure__indicator"
                >
                    <slot
                        v-if="hasIndicatorSlot"
                        name="indicator"
                    />
                    <svg
                        v-else
                        width="20"
                        height="20"
                        viewBox="-8 0 28 20"
                    >
                        <path d="M0 19.924l12-9.697L0 .53v19.394z" />
                    </svg>
                </div>
            </button>
        </component>
        <button
            v-else
            class="lni-c-disclosure__toggle"
            :aria-controls="`${id}_content`"
            :aria-expanded="isOpen ? 'true' : 'false'"
            @click="toggle"
        >
            <div
                v-if="leadingIndicator"
                class="lni-c-disclosure__indicator"
            >
                <slot
                    v-if="hasIndicatorSlot"
                    name="indicator"
                />
                <svg
                    v-else
                    width="20"
                    height="20"
                    viewBox="-8 0 28 20"
                >
                    <path d="M0 19.924l12-9.697L0 .53v19.394z" />
                </svg>
            </div>
            <slot name="toggle" />
            <div
                v-if="!leadingIndicator"
                class="lni-c-disclosure__indicator"
            >
                <slot
                    v-if="hasIndicatorSlot"
                    name="indicator"
                />
                <svg
                    v-else
                    width="20"
                    height="20"
                    viewBox="-8 0 28 20"
                >
                    <path d="M0 19.924l12-9.697L0 .53v19.394z" />
                </svg>
            </div>
        </button>
        <div
            :id="`${id}_content`"
            v-slide="isOpen"
            class="lni-c-disclosure__content"
        >
            <slot name="content" />
        </div>
    </div>
</template>

<script>
import slide from '@gov.wa.lni/framework.one-lni.directives/source/slide.js';
/* Commonly used phrasing content elements are allowed inside a button and heading elements are handled by the component
https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/Content_categories#phrasing_content
*/
const allowedInButton = [
    'b',
    'code',
    'em',
    'i',
    'img',
    'small',
    'span',
    'strong',
    'sub',
    'sup',
    'svg',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
];
const invalidSlotMessage = `Some elements are not allowed within the toggle slot element. Use ${allowedInButton.join(', ')}`;
export default {
    name: 'LniDisclosure',
    directives: {
        slide,
    },
    data() {
        return {
            toggleWrap: '',
        };
    },
    computed: {
        // Indicator should point to the right
        // The right-facing indicator is styled downwards when expanded/open
        hasIndicatorSlot() {
            return !!this.$slots.indicator;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.processButtonSlot();
        });
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
            this.$emit('toggle');
        },
        /* Handle cases where 1)headings need to be moved to outside of button 2)slot contains
        * invalid children of button. Will not break if invalid elements are used, only warns.
        */
        processButtonSlot() {
            if (this.$slots.toggle.length === 1 ) {
                // Happiest path - one root node.
                const rootNode = {
                    ...this.$slots.toggle[0],
                };
                if (!rootNode.children.every(node => allowedInButton.includes(node.tag))) {
                    // Only checking one level deep for invalid elements
                    console.warn(invalidSlotMessage);
                }
                if ( rootNode.tag.startsWith('h') || rootNode.tag === 'div') {
                    //redefine as a span but keep children and vnode.data
                    this.$slots.toggle =  [{
                        children: rootNode.children,
                        data: rootNode.data,
                        tag: 'span',
                    }];
                    if (rootNode.tag.startsWith('h')) {
                        this.toggleWrap = rootNode.tag;
                    }
                    //changing slot doesn't trigger update
                    this.$forceUpdate();
                }
            } else if (this.$slots.toggle.length > 1) {
                // Multiple roots (template was used)
                if (!this.$slots.toggle.every(node => allowedInButton.includes(node.tag)) ) {
                    console.warn(invalidSlotMessage);
                }
            }
        },
    },
}; </script>: