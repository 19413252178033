import updateEmail from '@gov.wa.lni/component.lni-input-email/actions/updateEmail.js';
import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';
import text from '@gov.wa.lni/framework.one-lni.core/source/lib/getText.js';

export default () => ({
    state: {
        lang: '',
        legend: '',
        labelText: '',
        labelBefore: false,
        parentLegend: '',
        changeAction: '',
        inputAction: '',
        value: '',
        filled: true,
        dense: false,
        required: false,
        hasInlineMessages: true,
        fullWidth: false,
        rtl: false,
        minlength: false,
        maxlength: false,
        messages: {
            valueMissing: {
                global: state => interpolate(
                    text(state.lang, 'lni-input-email').messages.valueMissing.global, {
                        labelText: state.labelText,
                        legend: state.legend,
                    },
                ),
                inline: state =>
                    text(state.lang, 'lni-input-email').messages.valueMissing.inline,
            },
            /* value does not match pattern attribute/regex */
            patternMismatch: {
                global: state => interpolate(
                    text(state.lang, 'lni-input-email').messages.patternMismatch.global, {
                        labelText: state.labelText,
                        legend: state.legend,
                    },
                ),
                inline: state => {
                    const example = state.example ? '. Example: ' + state.example : '';
                    return interpolate(
                        text(state.lang, 'lni-input-email').messages.patternMismatch.inline, {
                            example,
                        },
                    );
                },
            },
            /* value does not match input type */
            typeMismatch: {
                global: state => interpolate(
                    text(state.lang, 'lni-input-email').messages.typeMismatch.global, {
                        labelText: state.labelText,
                        legend: state.legend,
                    },
                ),
                inline: state => {
                    const example = state.example ? '. Example: ' + state.example : '';
                    return interpolate(
                        text(state.lang, 'lni-input-email').messages.typeMismatch.inline, {
                            example,
                        },
                    );
                },
            },
        },
        errorText: '',
        errors: [],
        validity: null,
        flags: {
            dirty: false,
            touched: false,
        },
        inputValidationAction: 'validate',
        changeValidationAction: 'validate',
        blurValidationAction: 'validate',

        errorsCollectedFrom: [
            id => `${id}_input-text`,
        ],
    },
    actions: {
        updateEmail,
    },
});