// validation pipeline.
export default (context, payload) => {
    const moduleState = context.rootState[payload.targetId];
    const pattern = moduleState.pattern;

    let leapYear = false;
    const moduleId = context.getters.moduleId;

    if (context.rootState[`${moduleId}_year`].validity) {
        leapYear = context.rootState[`${moduleId}_year`].validity.leapYear;
    }
    const month = context.rootState[`${moduleId}_month`].value;
    const day = context.rootState[`${moduleId}_day`].value;
    const year = context.rootState[`${moduleId}_year`].value;
    let validity = {};
    if (moduleState.validity) {
        validity = {
            ...moduleState.validity,
        };
    }

    let yearValidity = {};
    if (year.validity) {
        yearValidity = {
            ...year.validity,
        };
    }

    // If the date input is not required and there is no value in any of the inputs
    // do not make any of them invalid.
    if (!moduleState.required
        && (month.length === 0 && day.length === 0 && year.length === 0)) {
        validity.invalidDay = false;
        return validity;
    }

    //Test for invalid Month (pattern)
    validity.invalidDay = !pattern.test(moduleState.value);

    // Test for last day of month
    if (month && day) {

        //Test months with 31 days
        if ((/^(0?[1|3|5|7|8]|1[02])/).test(month)) {
            validity.monthEnd = !(/(0?[1-9]|1[0-9]|2[0-9]|3[01])$/).test(day);
        }

        //Test months with 30 days
        if ((/^(0?[4|6|9]|11)/).test(month)) {
            validity.monthEnd = !(/(0?[1-9]|1[0-9]|2[0-9]|30)$/).test(day);
        }

        //Test february
        if ((/^0?2/).test(month)) {
            validity.monthEnd = !(/(0?[1-9]|1[0-9]|2[0-8])$/).test(day);
            yearValidity.leapYear = false;

            context.commit('setAttribute', {
                id: `${moduleId}_year`,
                attribute: 'validity',
                value: yearValidity,
            }, {
                root: true,
            });

            context.dispatch('updateValidity', {
                targetId: `${moduleId}_year`,
                yearValidity,
            }, {
                root: true,
            }).then(() => {
                // Now we call the generic validate function on this component which will kick off
                // the regular pipeline to validate itself and those above it.
                context.dispatch('validate', {
                    targetId: `${moduleId}_year`,
                    validation: {
                        errorsCollectedBy: moduleState.errorsCollectedBy,
                    },
                }, {
                    root: true,
                });
            });

            if (!leapYear && day === '29') {
                validity.monthEnd = false;
            }
        }
    }

    return validity;
};