import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';
import text from '@gov.wa.lni/framework.one-lni.core/source/lib/getText.js';

export default () => ({
    state: {
        lang: '',
        changeAction: '',
        value: 'on',
        checked: false,
        classString: '',
        hideLabel: false,
        required: false,
        disabled: false,
        labelText: '',
        description: '',
        legend: '',
        hasInlineMessages: true,
        messages: {
            valueMissing: {
                global: state => interpolate(
                    text(state.lang, 'lni-checkbox').messages.valueMissing.global, {
                        labelText: state.labelText,
                        legend: state.legend ? `${state.legend} ` : '',
                    },
                ),
                inline: state => text(state.lang, 'lni-checkbox').messages.valueMissing.inline,
            },
        },
        errorText: '', // triggers error state in DOM
        errors: [],
        validity: null,
        flags: {
            dirty: false,
            touched: false,
        },
        changeValidationAction: 'validate',
    },
});