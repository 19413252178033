export default (context, payload) => {
    const textInputId = `${payload.componentId}_input-text`;
    const textInputValue = context.rootState[textInputId].value;
    const componentValue = context.rootState[payload.componentId].value;

    if (textInputValue !== componentValue) {
        context.commit('setAttribute', {
            id: textInputId,
            attribute: 'value',
            value: context.rootState[payload.componentId].value,
        }, {
            root: true,
        });
    }
};