import text from '@gov.wa.lni/framework.one-lni/source/locale/en.js';

export default () => ({
    state: {
        labelText: text['lni-input-search'].labelText,
        required: false,
        classString: '',
        placeholder: '',
        query: '',
        searchAction: '',
        emptySearchAction: '',
        clearAction: '',
        inputAction: '',
        blurAction: '',
        focusAction: '',
        pasteAction: '',
        maxlength: false,
        sendPagerParameter: true,
        autocomplete: null,
        name: 'query',
    },
});