const compareAsc = (a, b) => {
    if (a.value > b.value) {
        return 1;
    }
    if (a.value < b.value) {
        return -1;
    }
    return 0;
};

const compareDesc = (a, b) => {
    if (a.value < b.value) {
        return 1;
    }
    if (a.value > b.value) {
        return -1;
    }
    return 0;

};

export default (items, sortField, sortDirection) => {
    if (!(items && Array.isArray(items))) {
        return null;
    }

    if (!(sortField && sortDirection)) {
        return items;
    }

    let sortFieldNotValid = false;
    const mapped = items.map((item, i) => {
        if (item[sortField] === null || item[sortField] === undefined) {
            sortFieldNotValid = true;
        }

        return {
            index: i,
            value: item[sortField],
        };
    });

    if (sortFieldNotValid) {
        return items;
    }

    if (sortDirection === 'asc') {
        mapped.sort(compareAsc);
    } else {
        mapped.sort(compareDesc);
    }

    return mapped.map(item => items[item.index]);
};