import readableList from '@gov.wa.lni/framework.one-lni.core/source/lib/readableList.js';
import actions from '@gov.wa.lni/component.lni-input-password/actions';

const hasLowerCase = string => (/[a-z]/).test(string);
const hasUpperCase = string => (/[A-Z]/).test(string);
const hasNumber = string => (/\d/).test(string);
// eslint-disable-next-line
const hasSpecialCharacter = string => (/[~`!@#\(\)$%\^&*+=\-\[\]\\';._,/{}|\\":<>\?]/g).test(string);

export default () => ({
    state: {
        value: '',
        maxlength: null,
        minlength: 10,
        disabled: false,
        required: true,
        isNewPassword: false,
        changeAction: '',
        inputAction: '',
        hasInlineMessages: true,
        fullWidth: false,
        errorText: '',
        errors: [],
        name: '',
        validity: {
            valueMissing: false,
            tooShort: false,
            requirementsMissing: false,
        },
        flags: {
            dirty: false,
            touched: false,
        },
        changeValidationAction: 'validate',
        inputValidationAction: 'validate',
        blurValidationAction: 'validate',
        customValidityAction: '',
        customValidationAction: 'customValidate',
        messages: {
            requirementsMissing: {
                /* eslint-disable max-len */
                inline: state => {
                    const missing = state.missingCharacterRequirements;
                    const friendly = {
                        hasLowerCase: 'a lowercase letter',
                        hasUpperCase: 'an uppercase letter',
                        hasNumber: 'a number',
                        hasSpecialCharacter: 'a special character (examples: "#$%&*+=")',
                    };
                    const options = missing.map(req => friendly[req]);
                    return `Please use at least ${missing.length - 1} of the following: ${readableList(options, 'or')}`;
                },
                global: () => 'Please create a password that meets the requirements above.',
                /* eslint-enable max-len */
            },
            tooShort: {
                inline: state => {
                    const shortBy = state.minlength - state.value.length;
                    return `Please use at least ${shortBy} more character${shortBy > 1 ? 's' : ''}.`;
                },
                global: state => {
                    const shortBy = state.minlength - state.value.length;
                    return `Please use at least ${shortBy} more character${shortBy > 1 ? 's' : ''} in your password.`;
                },
            },
            valueMissing: {
                inline: () => 'A password is required.',
                global: () => 'A password is required.',
            },
        },
        errorsCollectedFrom: [
            id => `${id}_input-text`,
        ],
        characterRequirements: [
            'hasLowerCase',
            'hasUpperCase',
            'hasNumber',
            'hasSpecialCharacter',
        ],
        missingCharacterRequirements: [],
        minCharacterRequirements: 3,
        isValid: false,

    },
    getters: {
        tooShort(state) {
            return state.value.length < state.minlength;
        },
        hasLowerCase(state) {
            return hasLowerCase(state.value);
        },
        hasUpperCase(state) {
            return hasUpperCase(state.value);
        },
        hasNumber(state) {
            return hasNumber(state.value);
        },
        hasSpecialCharacter(state) {
            return hasSpecialCharacter(state.value);
        },
        metRequirements(state, getters) {
            return state.characterRequirements.filter(requirement => getters[requirement]);
        },
        missingRequirements(state, getters) {
            return state.characterRequirements.filter(requirement => !getters[requirement]);
        },
        isReallyValid(state, getters) {
            return getters.metRequirements.length >= state.minCharacterRequirements
                && !getters.tooShort && state.errorText === '';
        },
    },
    actions: {
        ...actions,
    },
});