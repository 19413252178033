import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';

const RangeSize = 5;

export default {
    name: 'lni-pager',

    data() {
        return {
            pagerRangeSize: RangeSize,
        };
    },
    render(createElement) {
        const pagingActions = [];
        if (!this.hidePagingActions) {
            pagingActions.push(this.generateActions(createElement));
        }

        return createElement('div', {
            class: 'lni-c-pager lni-u-mv1',
        }, pagingActions);

    },
    computed: {
        pagerRangeBuffer() {
            const halveDivisor = 2;
            return (this.pagerRangeSize - 1) / halveDivisor;
        },
        totalPages() {
            return Math.ceil(this.totalItems / this.pageSize);
        },
        computedSelectLabel() {
            return typeof this.selectLabel === 'string' ? this.selectLabel : this.selectLabel(this);
        },
    },
    methods: {
        changePage(page) {
            this.page = page;
            this.$emit('change-page', {
                page,
            });
            if (this.scrollTarget) {
                this.$scrollTo(this.scrollTarget);
            }
        },
        goto(page) {
            this.changePage(page);
        },
        previousPage() {
            if (this.page > 1) {
                this.changePage(this.page - 1);
            }
        },
        firstPage() {
            this.changePage(1);
        },
        nextPage() {
            if (this.page < this.totalPages) {
                this.changePage(this.page + 1);
            }
        },
        previousRange() {
            if ((this.page - this.pagerRangeSize) > 0) {
                this.changePage(this.page - this.pagerRangeSize);
            }
        },
        nextRange() {
            if ((this.page + this.pageSize) < this.totalPages) {
                this.changePage(this.page + this.pageSize);
            }
        },
        findRange() {
            const fullRange = [];
            let firstPage = this.page - this.pagerRangeBuffer;
            let lastPage = this.page + this.pagerRangeBuffer;

            if (lastPage > this.totalPages) {
                firstPage = this.totalPages - (this.pagerRangeSize - 1);
                lastPage = this.totalPages;
            }

            if (this.page < this.pagerRangeSize) {
                firstPage = 1;

                if (this.totalPages >= this.pagerRangeSize) {
                    lastPage = this.pagerRangeSize;
                } else {
                    lastPage = this.totalPages;
                }
            }

            for (let i = firstPage; i <= lastPage; i++) {
                fullRange.push(i);
            }

            return fullRange;
        },
        lastPage() {
            return Math.ceil(parseInt(this.total, 10) / this.itemsPerPage);
        },

        // These build urls used in the anchor tag href.  These are used when the anchor tag is
        // provided an actual url instead of handled by a click event.  It will do this if
        // the generateAnchorLinkUrl property is set to true
        previousPageRoute() {
            const previousRoutedPage = parseInt(this.page) - 1;
            if (previousRoutedPage < 1) {
                return false;
            }
            return this.buildRouteByPage(previousRoutedPage);
        },
        firstPageRoute() {
            return this.buildRouteByPage(1);
        },
        ellipsisRoute() {
            const ellipsisJump = 5;
            //TODO: what is the label for this ("Go back 5 pages?")
            return this.buildRouteByPage(parseInt(this.page, 10) - ellipsisJump);
        },
        nextPageRoute() {
            const nextRoutedPage = parseInt(this.page) + 1;
            if (nextRoutedPage > this.lastPage) {
                return false;
            }
            return this.buildRouteByPage(nextRoutedPage);
        },
        getHash(page) {
            return this.buildUrlFunction(page);
        },
        buildRouteByPage(page) {
            return `?index=${this.index}&query=${this.query}&pg=${parseInt(page, 10)}`;
        },
        generateActions(createElement) {
            if (!this.showPager) {
                return [this.generatePaginationComponent(createElement)];
            }
            return [
                this.generateSelectComponent(createElement),
                this.generatePaginationComponent(createElement),
            ];

        },
        generateSelectComponent(createElement) {
            return createElement('lni-select',
                {
                    attrs: {
                        id: `${this.collectionId}_PageSize`,
                        value: this.pageSize,
                        labelText: this.computedSelectLabel,
                        changeAction: `${this.collectionId}/changePageSizeValue`,
                        dense: true,
                        filled: this.filled,
                        classString: 'lni-u-mr3',
                        customWidth: '5',
                        options: this.options,
                    },
                },
            );
        },
        generatePaginationComponent(createElement) {
            // eslint-disable-next-line consistent-this
            const vm = this;
            let paginationComponents = [];

            const previousPage = createElement('a', {
                class: [
                    'lni-c-pager__toggle',
                    {
                        '--disabled': this.page === 1,
                    },
                ],
                attrs: {
                    href: this.generateAnchorLinkUrl ? this.previousPageRoute() : '#',
                    disabled: this.page === 1,
                    'aria-label': this.previousPageLabel(this),
                },
                on: {
                    click($event) {
                        if (!vm.generateAnchorLinkUrl) {
                            $event.preventDefault();
                            vm.previousPage();
                        }
                    },
                },
            }, [this.generateIcon(createElement, {
                iconName: 'caret--left',
                altText: this.previousPageLabel(this),
            })]);

            paginationComponents.push(previousPage);

            const range = this.findRange();
            const rangeIncludesPage1 = range.find(item => item === 1);
            if (this.page >= this.pagerRangeSize && !rangeIncludesPage1) {
                const page1 = createElement('a', {
                    class: [
                        'lni-c-pager__link',
                    ],
                    attrs: {
                        href: this.generateAnchorLinkUrl ? this.firstPageRoute() : '#',
                        'aria-label': this.firstPageLabel(this),
                    },
                    on: {
                        click($event) {
                            if (!vm.generateAnchorLinkUrl) {
                                $event.preventDefault();
                                vm.goto(1);
                            }
                        },
                    },
                }, ['1']);
                paginationComponents.push(page1);
                if (this.page >= this.pagerRangeSize + 1
                    && this.totalPages >= this.pagerRangeSize + 1) {

                    const ellipsis = createElement('a', {
                        class: [
                            'lni-c-pager__link',
                        ],
                        attrs: {
                            href: this.generateAnchorLinkUrl ? this.ellipsisRoute() : '#',
                            'aria-label': this.previousPageLabel(this),
                        },
                        on: {
                            click($event) {
                                if (!vm.generateAnchorLinkUrl) {
                                    $event.preventDefault();
                                    vm.previousRange();
                                }
                            },
                        },
                    }, ['...']);
                    paginationComponents.push(ellipsis);
                } else {
                    const ellipsis = createElement('span', {
                        class: [
                            'lni-c-pager__link',
                            '--disabled',
                        ],
                        attrs: {},
                    }, ['...']);
                    paginationComponents.push(ellipsis);
                }
            }
            if (range.length !== 0) {
                const rangeLinks = range.map(i => createElement('a', {
                    key: `page_${i}`,
                    class: [
                        'lni-c-pager__link',
                        'lni-u-text--center',
                        {
                            '--active': this.page === i,
                        },
                    ],
                    attrs: {
                        href: this.generateAnchorLinkUrl ? this.buildRouteByPage(i) : '#',
                        'aria-label': this.page === i ? interpolate(this.currentPageLabel(this), {
                            i,
                        }) : interpolate(this.goToPageLabel(this), {
                            i,
                        }),
                        'aria-current': this.page === i,
                    },
                    on: {
                        click($event) {
                            if (!vm.generateAnchorLinkUrl) {
                                $event.preventDefault();
                                vm.goto(i);
                            }
                        },
                    },
                }, [i]));

                paginationComponents = paginationComponents.concat(rangeLinks);
            }

            const nextPage = createElement('a', {
                class: [
                    'lni-c-pager__toggle',
                    {
                        '--disabled': this.page === Math.ceil(this.totalItems / this.pageSize),
                    },
                ],
                attrs: {
                    href: this.generateAnchorLinkUrl ? this.nextPageRoute() : '#',
                    disabled: this.page === Math.ceil(this.totalItems / this.pageSize),
                    'aria-label': this.nextPageLabel(this),
                },
                on: {
                    click($event) {
                        if (!vm.generateAnchorLinkUrl) {
                            $event.preventDefault();
                            vm.nextPage();
                        }
                    },
                },
            }, [this.generateIcon(createElement, {
                iconName: 'caret--right',
                altText: this.nextPageLabel(this),
            })]);
            paginationComponents.push(nextPage);

            const pager = createElement('nav',
                {
                    class: 'lni-c-pager__nav lni-u-text--right lni-u-inline lni-u-mb2',
                    attrs: {
                        role: 'navigation',
                        'aria-label': this.navLabel(this),
                    },
                },
                paginationComponents,
            );

            return pager;
        },
        generateIcon(createElement, attrs) {
            const className = `lnicon--${attrs.iconName} lni-c-icon`;
            return createElement('span', {
                attrs: {
                    'aria-hidden': 'true',
                    alt: attrs.altText,
                },
                class: className,
            });
        },
    },
};