

export default (context, payload) => new Promise(resolve => {
    const moduleState = context.rootState[payload.targetId];
    const value = context.state.value;
    const thisId = context.getters.moduleId;
    const inputTextId = `${thisId}_input-text`;
    const metRequirements = context.getters.metRequirements;
    const minRequirements = context.state.minCharacterRequirements;
    const tooShort = context.getters.tooShort;
    let validity = {};
    if (moduleState.validity) {
        validity = {
            ...moduleState.validity,
        };
    }
    const setTrailingIcon = () => {
        let iconState = '';
        const isReallyValid = context.getters.isReallyValid;
        const hasError = !context.getters.isValid;

        if ( isReallyValid && thisId.isNewPassword ) {
            iconState = 'checkmark';
        } else if ( hasError ) {
            iconState = 'exclamation';
        }

        context.commit('setAttribute', {
            id: inputTextId,
            attribute: 'trailingIcon',
            value: iconState,
        }, {
            root: true,
        });
    };

    validity.valueMissing = value.length  === 0;

    if (context.state.isNewPassword) {
        validity.tooShort = tooShort;
        validity.requirementsMissing = metRequirements.length < minRequirements;
    }

    // Set on state so it is available to the error message functions
    context.commit('setAttribute', {
        id: thisId,
        attribute: 'missingCharacterRequirements',
        value: context.getters.missingRequirements,
    }, {
        root: true,
    });

    context.dispatch('updateValidity', {
        targetId: payload.targetId,
        validity,
    }, {
        root: true,
    }).then(() => {
        if (context.state.flags.dirty) {
            context.dispatch('validate', {
                targetId: payload.targetId,
                validation: {
                    errorsCollectedBy: moduleState.errorsCollectedBy,
                },
            }, {
                root: true,
            }).then(() => {
                setTrailingIcon();
                resolve();
            });
        } else {
            setTrailingIcon();
            resolve();
        }
    });

});