const iconClasses = {
    error: 'exclamation',
    warn: 'warning',
    info: 'info',
};

export default {
    name: 'table-message',
    props: [
        'state',
        'noRowsMessage',
        'errorMessage',
        'customMessage',
    ],
    render(createElement) {
        switch (this.state) {
            case 'initial': return this.renderNothing(createElement);
            case 'no-data': return this.renderNoData(createElement);
            case 'warn': return this.renderWarn(createElement);
            case 'info': return this.renderInfo(createElement);
            case 'error': return this.renderError(createElement);
            default: return this.renderNoData(createElement);
        }
    },
    methods: {
        renderMessage(createElement, iconClass, message) {
            return createElement('span', {
                attrs: {
                    role: 'alert',
                },
                class: [
                    'lni-c-alert',
                    'lni-u-mv4',
                    'lni-u-mt2',
                    {
                        '--warning': this.state === 'warn',
                        '--error': this.state === 'error',
                        '--info': this.state === 'info',
                    },
                ],
            }, [
                createElement('span', {
                    attrs: {
                        'aria-hidden': true,
                    },
                    class: `lnicon--${iconClass}`,
                }),
                message,
            ],
            );
        },
        renderNothing(createElement) {
            return createElement('div');
        },
        renderNoData(createElement) {
            return this.renderMessage(createElement, iconClasses.info, this.noRowsMessage);
        },
        renderInfo(createElement) {
            return this.renderMessage(createElement, iconClasses.info, this.customMessage);
        },
        renderError(createElement) {
            return this.renderMessage(createElement, iconClasses.error, this.errorMessage);
        },
        renderWarn(createElement) {
            return this.renderMessage(createElement, iconClasses.warn, this.customMessage);
        },
    },
};