export default {
    name: 'table-body',
    props: ['currentPageOfItems'],
    render(createElement) {
        return createElement('tbody', this.generateRows());
    },
    methods: {
        generateRows(createElement) {
            if (!this.$scopedSlots.body) {
                return null;
            }

            const rows = [];
            for (let index = 0, len = this.currentPageOfItems.length; index < len; ++index) {
                const item = this.currentPageOfItems[index];
                const props = {
                    item,
                    index,
                };

                const row = this.$scopedSlots.body(props);

                // check if the row template is wrapped by a TR tag
                rows.push(
                    this.hasTag(row, 'td')
                        ? createElement('tr',
                            {
                                key: index,
                            }, [row])
                        : row);
            }

            return rows;
        },
        hasTag(elements, tag) {
            return Array.isArray(elements) && elements.find(e => e.tag === tag);
        },
    },
};