<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        :class="{
            '--error': level === 'Error',
            '--warn': level === 'Warning',
            '--info': level === 'Information',
            '--okay': level === 'Okay',
            '--large': large === true,
            'lni-u-shadow--sm lni-u-ph2': !large,
        }"
        class="
            lni-c-informational-message
            lni-u-mv2 lni-u-pv1
        "
        role="alert">
        <div class="lni-u-flex">
            <span
                aria-hidden="true"
                :class="`lnicon--${iconClass}`"
                class="lni-c-informational-message__icon lni-u-mr2"></span>
            <div>
                <span
                    v-if="!link"
                    :class="{'lni-u-heading--3': large}"
                    class="lni-c-informational-message__message">
                    {{ message }}
                </span>
                <lni-html-element
                    v-if="link"
                    :id="`${id}_link`"
                    element="a"
                    :href="link"
                    target="_blank"
                    class="lni-c-informational-message__link lni-c-anchor"
                    :htmlString="message"></lni-html-element>
                <div
                    v-if="hasDescription"
                    class="lni-c-informational-message__description">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const iconClasses = {
    Error: 'exclamation',
    Warning: 'warning',
    Information: 'info',
    Okay: 'checkmark',
};

export default {
    name: 'lni-informational-message',

    computed: {
        iconClass() {
            return iconClasses[this.level];
        },
        hasDescription() {
            return !!this.$slots.default;
        },
    },
}; </script>