export default (context, tableId) => {
    const page = context.rootState[tableId].page;
    const pageSize = context.rootState[tableId].pageSize;
    const sort = context.rootState[tableId].sort;
    const sortField = Object.keys(sort)[0];
    const sortDirection = sort[sortField];
    const items = context.rootState[tableId].items;
    return {
        page,
        pageSize,
        sortField,
        sortDirection,
        items,
    };
};