export default context => {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];

    context.commit('setAttribute', {
        id: moduleId,
        attribute: 'value',
        value: '',
    }, {
        root: true,
    });

    context.dispatch('syncToInnerComponent', {
        sourceId: moduleId,
        targetId: `${moduleId}_text`,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_text`,
        attribute: 'ariaActivedescendant',
        value: moduleState.activeDescendant,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: moduleId,
        attribute: 'cleared',
        value: true,
    }, {
        root: true,
    });
};