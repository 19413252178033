import * as sync from '@gov.wa.lni/component.lni-input-address/actions/sync.js';
import actions from '@gov.wa.lni/component.lni-input-address/actions';

import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';
import text from '@gov.wa.lni/framework.one-lni.core/source/lib/getText.js';

export default () => ({
    state: {
        lang: '',
        legend: '',
        labelText: '',
        labelBefore: false,
        isFieldset: true,
        address1: '',
        address1MaxLength: false,
        address2: '',
        address2MaxLength: false,
        city: '',
        cityMaxLength: false,
        state: '',
        zip: '',
        filled: false,
        dense: false,
        required: false,
        mountAction: '',
        inputAction: '',
        changeAction: '',
        county: '',
        countyOptions: [],
        label: state => text(state.lang, 'lni-input-address').labelText,
        zipPattern: '(\\d{5}([\\-]\\d{4})?)', //Note: double escaping required
        messages: {
            valueMissing: {
                global: state => interpolate(
                    text(state.lang, 'lni-input-address').messages.valueMissing.global,
                    {
                        labelText: state.labelText,
                        legend: state.legend ? `${state.legend} ` : '',
                    }),
                inline: state => text(state.lang, 'lni-input-address').messages.valueMissing.inline,
            },
            badZip: {
                // eslint-disable-next-line max-len
                global: state => interpolate(text(state.lang, 'lni-input-address').messages.badZip.global, {
                    labelText: state.label,
                    legend: state.legend ? `${state.legend} ` : '',
                }),
                inline: state => text(state.lang, 'lni-input-address').messages.badZip.inline,
            },
        },
        hasInlineMessages: true,
        validity: {
            valueMissing: false,
            badZip: false,
        },
        errorText: '',
        errors: [],
        flags: {
            dirty: false,
            touched: false,
        },
        customValidationAction: 'customValidate',
        errorsCollectedFrom: [
            id => `${id}_address1`,
            id => `${id}_address2`,
            id => `${id}_city`,
            id => `${id}_state`,
            id => `${id}_zip`,
        ],
    },
    actions: {
        ...sync,
        ...actions,
    },
});