<template>
    <fieldset
        :id="id"
        :class="{ '--required': required, '--column': isColumn }"
        class="lni-c-radio__fieldset"
        @focus="focusOnFirstInput">
        <legend 
            v-if="labelText" 
            class="lni-c-radio__legend  lni-u-line-height--tight">
            {{ labelText }}
        </legend>

        <div class="lni-c-contextual-help__wrapper">
            <slot name="contextual-help"></slot>
        </div>

        <div
            v-if="hasInlineMessages || hasHelperText || hasErrorText"
            :id="`${id}_helper-text`"
            :class="hasErrorText ? 'lni-c-fieldset__error-message' : 'lni-c-fieldset__helper-text'"
            class="lni-c-contextual-help__wrapper lni-u-type--xxs lni-u-line-height--tight">
            <template v-if="hasErrorText">
                <span
                    class="lni-u-font-color--error"
                    role="alert">
                    {{ errorText }}
                </span>
            </template>
            <template v-if="!hasErrorText">
                <slot name="helperText"></slot>
            </template>
        </div>
        <div
            class="lni-u-flex lni-u-flex-wrap"
            :class="{ 'lni-u-flex-column lni-u-items-start': isColumn, 'lni-u-mt1' : !hasInlineMessages && !hasHelperText }">
            <div
                v-for="option in options"
                :key="option.value"
                class="lni-c-radio">
                <input
                    :id="id + '_' + option.value"
                    :name="id"
                    :value="option.value"
                    :class="{'--hasFocus' : hasKeyboardFocus && focusedRadio === option.value}"
                    class="lni-c-radio__input"
                    type="radio"
                    :checked="option.value === value"
                    @change="onChange"
                    @keyup="showKeyboardFocus(option.value, $event)"
                    @click="hasKeyboardFocus = false"
                    @blur="hasKeyboardFocus = false">
                <label
                    :for="id + '_' + option.value"
                    class="lni-c-radio__label">
                    <span class="lni-c-radio__indicator"></span>
                    <span class="lni-c-radio__label-text">{{ option.text }}</span>
                </label>
                <p
                    v-if="option.description"
                    class="lni-c-radio__description">
                    {{ option.description }}
                </p>
            </div>
        </div>
    </fieldset>
</template>

<script>
/**
 *  The lni-radio component.
 *  @module components/lni-radio
 */

/**
 * The lni-radios is a basic radio button list.
 */
export default {
    name: 'lni-radios',

    data() {
        return {
            focusedRadio: null,
            hasKeyboardFocus: false,
        };
    },
    computed: {
        hasErrorText() {
            return !!this.$store.state[this.id].errorText;
        },
        hasHelperText() {
            return !!this.$slots.helperText;
        },
    },
    mounted() {
        this.updateValidity();
        this.$watch('value', () => {
            this.updateValidity();
        });
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            const mobileBreakPoint = '400px';
            const mq = window.matchMedia(`(max-width: ${mobileBreakPoint})`);
            if (mq.matches || this.isColumn) {
                this.$store.commit('setAttribute', {
                    id: this.id,
                    attribute: 'isColumn',
                    value: true,
                });
            } else {
                this.$store.commit('setAttribute', {
                    id: this.id,
                    attribute: 'isColumn',
                    value: false,
                });
            }
        },
        onChange(event) {
            this.value = event.target.value;
            this.$store.commit(`${this.id}/setDirty`, {
                value: true,
            });
            this.updateValidity().then(() => {
                if (this.$store.state[this.id].flags.dirty && this.changeValidationAction) {
                    this.dispatchEvent('changeValidationAction');
                }

                this.dispatchEvent('changeAction').then(() => {
                    this.$emit('change');
                });
            });
        },
        updateValidity() {
            return this.$store.dispatch(`updateValidity`, {
                targetId: this.id,
            });
        },
        showKeyboardFocus(ref) {
            this.focusedRadio = ref;
            this.hasKeyboardFocus = true;
        },
        focusOnFirstInput() {
            this.hasKeyboardFocus = true;
            this.focusedRadio = this.options[0].value;
            let el = this.$el.querySelector('input');
            this.$nextTick(() => el.focus());
        },
    },
}; </script>