
const updateParent = (childAttribute, parentAttribute, context, payload) => {
    context.commit('setAttribute', { // state[payload.id][payload.attribute] = payload.value;
        id: payload.sourceId, //source is actually parent (address)
        attribute: parentAttribute,
        value: context.rootState[payload.targetId][childAttribute], //target is child (input)
    }, {
        root: true,
    });
};

export const updateAddress1
    = (context, payload) => updateParent('value', 'address1', context, payload);
export const updateAddress2
    = (context, payload) => updateParent('value', 'address2', context, payload);
export const updateCity = (context, payload) => updateParent('value', 'city', context, payload);
export const updateState = (context, payload) => updateParent('value', 'state', context, payload);
export const updateZip = (context, payload) => updateParent('value', 'zip', context, payload);
export const updateCounty = (context, payload) => updateParent('value', 'county', context, payload);