import actions from '@gov.wa.lni/component.lni-table/actions';
import text from '@gov.wa.lni/framework.one-lni/source/locale/en.js';

export default () => ({
    state: {
        /*
            The state of the data for the table
            initial|no-data|error|info|warn|loading|loaded
        */
        loadState: 'initial',

        /*
            The message displayed when there is a 'warn' or 'info' loadState
        */
        customMessage: '',

        /*
            The default message displayed when there is a 'no-data' loadState
        */
        noRowsMessage: text['lni-table'].noRowsMessage,

        /*
            The default message displayed when there is an 'error' loadState
        */
        errorMessage: text['lni-table'].errorMessage,

        /*
            The array of data items.
            This can be set at initialization of the component or after through
            an interaction.
        */
        items: null,

        /*
            The array of column definitions.
            A column definition has the following properties:
            --source
            --displayText
            --sortable
            --headerClasses
        */
        columns: [],

        /*
            An object that defines the sort column.
            Only 1 column is currently supported.
            The object should look like this:
            {
                firstName: 'asc'
            }
        */
        sort: {},

        /*
            Defines the style of the Page Size Select component
        */
        filled: false,

        /*
            Defines the whether the rows of the table will be alternatingly shaded
        */
        striped: false,

        /*
            Defines whether each table refresh(through paging or sorting) will
            be done with data in memory or by calling the setData action.
        */
        inPlacePagingAndSorting: true,

        /*
            Defines whether to show the paging and page size selectors
        */
        hidePagingActions: false,

        /*
            The default page size
        */
        pageSize: 5,

        /*
            The page size options
        */
        pageSizeOptions: [
            {
                text: '5',
                value: 5,
            },
            {
                text: '10',
                value: 10,
            },
            {
                text: '20',
                value: 20,
            },
            {
                text: '50',
                value: 50,
            },
        ],

        /*
            The action called when the data is refreshed, when the the paging/sorting
            is not done in place.
        */
        setDataAction: [],

        /*
            The caption text
        */
        caption: '',

        /*
            Whether to show the caption. If hidden, it will be just for screen readers.
        */
        showCaption: false,

        /*
            The summary text.  This is for screen readers.  It shows up as an
            attribute on the table element.  It is not visible.
        */
        summary: '',

        /*
            The element at top of table to scroll to after paging
        */
        scrollTarget: '',
        /*
            Note: Do not set these values.  They are used internally by the component.
        */
        currentPageOfItems: [],
        totalItems: 0,
        itemsAreLoaded: false,
        page: 1,
        displayedCaption: null,
    },
    actions: {
        ...actions,
    },
});