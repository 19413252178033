import actions from '@gov.wa.lni/component.lni-file-upload/actions';
import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';
import text from '@gov.wa.lni/framework.one-lni/source/locale/en.js';

const fileSizeConversion = function(bit) {
    const b2mb = 1048576;
    const b2kb = 1024;
    const decimals = 2;
    const megaSize = bit / b2mb;
    const kiloSize = bit / b2kb;

    if (megaSize > 1) {

        return `${parseFloat(megaSize.toFixed(decimals))} MB`;
    }
    return `${parseFloat(kiloSize.toFixed(decimals))} kb`;
};

export default () => ({
    state: {
        text: text['lni-file-upload'],
        files: [], //  An array representing the selected files.
        uploadedFiles: [], //An array representing the successfully uploaded files
        fileTypes: [], // The list of files only that are allowed
        sizeLimit: 7340032,  //7MB by default,
        fileLimit: 1,
        hasVideos: false, // type of attachment. file size varies based on videos or documents
        multiple: true,
        name: '',
        fileSizeConversion,
        required: false,
        startTimer: false,
        ref: 'input',
        value: '',
        inputAction: '',
        blurAction: '',
        enterAction: '',
        changeAction: '',
        deleteAction: '',
        hasInlineMessages: false,
        errorText: '',
        showFileNumberLimitText: true,
        showFileList: true,
        showValidFileTypes: false,
        messages: {
            valueMissing: {
                global: () => text['lni-file-upload'].messages.valueMissing.global,
                inline: () => text['lni-file-upload'].messages.valueMissing.inline,
            },
            invalidFileSize: {
                global: state => {
                    const sizeLimit = `${fileSizeConversion(state.sizeLimit)}`;
                    return interpolate(text['lni-file-upload'].messages.invalidFileSize.global, {
                        sizeLimit,
                    });
                },
                inline: () => text['lni-file-upload'].messages.invalidFileSize.inline,
            },
            uploadLimit: {
                global: state => interpolate(text['lni-file-upload'].messages.uploadLimit.global, {
                    fileLimit: state.fileLimit,
                } ),
                inline: () => text['lni-file-upload'].messages.uploadLimit.inline,
            },
            invalidFileType: {
                global: state => {
                    let properExtensionList;
                    if (state.fileTypes.length === 0) {
                        properExtensionList = '';
                    } else if (state.fileTypes.length === 1) {
                        properExtensionList = state.fileTypes[0];
                    } else {
                        let extensionList = state.fileTypes.join(', ');
                        properExtensionList = extensionList.substr(0, extensionList
                            .lastIndexOf(' ')) + ' or ' + extensionList
                            .substr(extensionList.lastIndexOf(' ') + 1);
                    }
                    return interpolate(text['lni-file-upload'].messages.invalidFileType.global, {
                        properExtensionList,
                    });
                },
                inline: () => text['lni-file-upload'].messages.invalidFileType.inline,
            },
        },
        errors: [],
        validity: null,
        flags: {
            dirty: false,
            touched: false,
        },
        inputValidationAction: 'validate',
        changeValidationAction: 'validate',
        blurValidationAction: 'validate',
        customValidityAction: '',
        customValidationAction: '',
    },
    actions: {
        ...actions,
    },
    mutations: {
        CLEAR_FILES(state) {
            state.files.splice(0, state.files.length);
        },
    },
});