<template>
    <section
        v-show="tabIsVisible"
        :id="'panel_' + id"
        role="tabpanel"
        :aria-labelledby="id"
        :tabindex="0"
        class="lni-c-tab-panel lni-u-mh3"
        :class="classString">
        <slot></slot>
    </section>
</template>

<script>/**
 *  The lni-tab-panel component.
 *  @module components/lni-tab-panel
 */

/**
 * lni-tab-panel Component is a tab panel container
 */
export default {
    name: 'lni-tab-panel',

    created() {
        this.tabIsVisible = this.showTab;
    },
    beforeUpdate() {
        this.tabIsVisible = this.showTab;
    },
}; </script>