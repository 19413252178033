// validation pipeline.
export default (context, payload) => {
    const moduleState = context.rootState[payload.targetId];
    const pattern = moduleState.pattern;
    const moduleId = context.getters.moduleId;
    const month = context.rootState[`${moduleId}_month`].value;
    const day = context.rootState[`${moduleId}_day`].value;
    const year = context.rootState[`${moduleId}_year`].value;
    const leapDay = 29;

    let validity = {};
    if (moduleState.validity) {
        validity = {
            ...moduleState.validity,
        };
    }

    // If the date input is not required and there is no value in any of the inputs
    // do not make any of them invalid.
    if (!moduleState.required
        && (month.length === 0 && day.length === 0 && year.length === 0)) {
        validity.invalidYear = false;
        return validity;
    }

    // Test valid pattern
    //Test for invalid Year (pattern)
    validity.invalidYear = !pattern.test(moduleState.value);

    // Test valid Leap Year
    if ((/^2|02/).test(month) && day === leapDay.toString() && year) {
        validity.leapYear = !(/(((19|20)(04|08|[2468][048]|[13579][26]))|2000)/).test(year);
    }

    return validity;
};