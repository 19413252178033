<template>
    <fieldset
        :id="id"
        :class="[
            'lni-u-inline-block',
            'lni-c-view-toggle',
            classString
        ]">
        <legend class="lni-u-type--xxs lni-u-line-height--tight lni-u-text--center">
            Grid View | List View
        </legend>
        <div class="lni-u-flex">
            <div class="lni-u-flex">
                <input
                    :id="`${id}_grid`"
                    class="lni-c-view-toggle__input"
                    :name="id"
                    value="grid"
                    type="radio"
                    :checked="value === 'grid'"
                    @change="onChange('grid')"/>
                    <label
                        :for=" `${id}_grid`"
                        class="lni-c-view-toggle__label --grid lni-u-flex lni-u-items-center">
                        <span class="lni-u-visually-hidden">Grid view</span>
                        <lni-svg-icon
                            v-show="value === 'grid'"
                            :id="`${id}_grid-svg--blue`"
                            fill="catalina-blue"
                            stroke="white"
                            :width="24"
                            :height="24"
                            viewBox="0 10 32 32"
                            md>
                            <grid-view></grid-view>
                        </lni-svg-icon>
                        <lni-svg-icon
                            v-show="value !== 'grid'"
                            :id="`${id}_grid-svg--white`"
                            fill="mid-gray"
                            stroke="white"
                            :width="24"
                            :height="24"
                            viewBox="0 10 32 32"
                            md>
                            <grid-view></grid-view>
                        </lni-svg-icon>
                    </label>
            </div>
            <div class="lni-u-flex">
                <input
                    :id="`${id}_list`"
                    class="lni-c-view-toggle__input"
                    :name="id"
                    value="list"
                    type="radio"
                    :checked="value === 'list'"
                    @change="onChange('list')" />
                <label
                    :for="`${id}_list`"
                    class="lni-c-view-toggle__label --list lni-u-flex lni-u-items-center">
                    <span class="lni-u-visually-hidden">List view</span>
                    <span
                        class="lnicon--list lni-u-type--lg"
                        aria-hidden="true"></span></label>
            </div>
        </div>
    </fieldset>
</template>

<script>
import icons from '@gov.wa.lni/component.lni-view-toggle/icons';

export default {
    name: 'LniViewToggle',
    components: {
        ...icons,
    },
    methods: {
        onChange(view) {
            if (view !== this.$store.state[this.id].value) {
                this.$store.commit('setAttribute', {
                    id: this.id,
                    attribute: 'value',
                    value: view,
                }, {
                    root: true,
                });

                this.dispatchEvent('changeAction').then(() => {
                    this.$emit('change', view);
                });
            }
        },
    },
}; </script>