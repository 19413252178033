<template>
    <button
        :id="id"
        :class="[
            classString,
            'lni-c-button',
            'lni-u-text--all-caps',
            'lni-u-nowrap',
            type ? `--${type}`: null
        ]"
        type="button"
        :disabled="disabled"
        @click="onClick">
        {{ text | buttonMaxWidth }}
    </button>
</template>

<script>/**
 *  The lni-button component.
 *  @module components/lni-button
 *  @param {String} text The text to display inside the button (MaxLength=25)
 *  @param {String} type The type of style this button will reflect options(cta, text, alternate)
 *  @param {Boolean} disabled Determines whether the state of the button is disabled or not
 */

const maxLength = 35;
/**
 * This component wraps the html button component.
 */

export default {
    name: 'lni-button',
    filters: {
        buttonMaxWidth(value) {
            if (!value) {
                return 'Button Text';
            }

            if (value.length > maxLength) {
                console.warn(`Button Text surpasses max character length of 25.
                  Please modify the button text`);
            }
            return value.slice(0, maxLength);
        },
    },
    methods: {
        onClick() {
            this.dispatchEvent('clickAction');
            this.$emit('click');
        },
    },
}; </script>