// This is a custom validation function that allows us to take full control of the
// validation pipeline.
export default (context, payload) => new Promise(resolve => {
    // get the existing validity object for this lni-input-address.
    // It has the custom validation tests
    const moduleState = context.rootState[payload.targetId];
    let validity = {};
    if (moduleState.validity) {
        validity = {
            ...moduleState.validity,
        };
    }

    const monthState = context.rootState[`${payload.targetId}_month`];
    const dayState = context.rootState[`${payload.targetId}_day`];
    const yearState = context.rootState[`${payload.targetId}_year`];

    const monthValidity = monthState.validity;
    const dayValidity = dayState.validity;
    const yearValidity = yearState.validity;

    const monthValueExists = monthState.value.length > 0;
    const dayValueExists = dayState.value.length > 0;
    const yearValueExists = yearState.value.length > 0;

    // In the general update validity action, all of the child components of
    // this compound component have had their errors collected by this component.
    // We can use this list of errors to generate a custom error message for just the
    // date component.  We set 'hasInlineMessages' to false on the child components
    // so that their error messages do not get rendered.
    const hasValue = monthValueExists || dayValueExists || yearValueExists;
    validity.dateIsInvalid
        = hasValue
            && (dayValidity.invalidDay || monthValidity.invalidMonth || yearValidity.invalidYear);
    validity.inputMissing = moduleState.required && !moduleState.value;

    if (validity.dateIsInvalid) {
        // grab the existing message functions defined in the component module.
        const newMessageFunctions = {
            ...moduleState.messages.dateIsInvalid,
        };

        const firstError
            = moduleState.errors.find(error => error.validationTest !== 'dateIsInvalid');
        newMessageFunctions.inline = () => firstError.inline;

        // use the following mutation update the message functions on the component store
        context.commit('customizeValidationMessage', {
            validationTest: 'dateIsInvalid',
            ...newMessageFunctions,
        });
    }

    // Now we have to have our newly created validity object set to the components store module
    context.dispatch('updateValidity', {
        targetId: payload.targetId,
        validity,
    }, {
        root: true,
    }).then(() => {
        // Now we call the generic validate function on this component which will kick off
        // the regular pipeline to validate itself and those above it.
        context.dispatch('validate', {
            targetId: payload.targetId,
            validation: {
                errorsCollectedBy: moduleState.errorsCollectedBy,
            },
        }, {
            root: true,
        }).then(() => {
            resolve();
        });
    });
});