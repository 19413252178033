<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="lni-c-toggle-tip lni-u-inline-block">
        <div
            v-if="!toggleSelf"
            class="lni-u-inline-block"
            @mouseover="showContent = true"
            @mouseleave="showContent = false">
            <slot></slot>
        </div>
        <span
            class="lni-c-toggle-tip__wrapper">
            <button
                v-click-outside="onClickOutside"
                type="button"
                :aria-label="!showLabel ? labelText : false"
                :aria-expanded="`${showContent}`"
                :aria-controls="`${id}_toggle-tip`"
                class="lni-c-toggle-tip__toggle lni-u-link-style"
                @click="showContent = !showContent"
                @keydown.enter.prevent="showContent = !showContent"
                @keydown.esc="showContent = false"
                @mouseover="toggleSelf ? hover = true : null"
                @mouseleave="toggleSelf ? hover = false : null">
                <slot v-if="toggleSelf"></slot>
                <span
                    v-else
                    aria-hidden="true"
                    :class="`lnicon--${icon}`"></span>{{ showLabel ? labelText : '' }}</button>
            <transition name="lni-o-fade">
                <span
                    v-show="showContent || (showOnHover && hover)"
                    key="div"
                    @mouseover="hover = true"
                    @mouseleave="hover = false">
                    <lni-html-element
                        :id="`${id}_toggle-tip`"
                        ref="tip"
                        key="item"
                        element="span"
                        :class="position"
                        class="lni-c-toggle-tip__content lni-o-type-scope--small"
                        role="status"
                        :htmlString="tip"></lni-html-element>
                    <span key="bottom"></span>
                    <span key="top"></span>
                </span>
            </transition>
        </span>
    </div>
</template>

<script>
import clickOutside from '@gov.wa.lni/framework.one-lni.directives/source/clickOutside.js';

export default {
    name: 'lni-toggle-tip',
    directives: {
        clickOutside,
    },
    data() {
        return {
            position: '',
        };
    },
    mounted() {
        this.$watch('showContent', () => {
            if (this.showContent && this.position === '') {
                this.setPosition();
            }
        });
    },
    methods: {
        setPosition() {
            const half = 2;

            const toggleX = this.$refs.tip.offsetParent ? this.$refs.tip.offsetParent.offsetLeft : this.$refs.tip.parentElement.offsetLeft;
            const toggleY = this.$refs.tip.offsetParent ? this.$refs.tip.offsetParent.offsetTop : this.$refs.tip.parentElement.offsetTop;
            const toggleWidth = this.$refs.tip.offsetParent ? this.$refs.tip.offsetParent.clientWidth : this.$refs.tip.parentElement.clientWidth;

            const tipWidth = this.$refs.tip.clientWidth;
            const tipHeight = this.$refs.tip.clientHeight;
            const tipTop = toggleY - tipHeight + this.$refs.tip.offsetTop;
            const tipStart = toggleX - (toggleWidth / half) - (tipWidth / half) + this.$refs.tip.offsetLeft;
            const tipEnd = tipStart + tipWidth;

            let result = this.direction === 'horizontal' ? '--west' : '--north';
            if (this.direction === 'horizontal') {
                if (tipStart <= 0) {
                    result = '--east';
                }
                // Adjust vertically
                if (tipTop <= 0) {
                    result += ' --down';
                } else {
                    result += ' --up';
                }

            } else {
                if (tipTop <= 0) {
                    result = '--south';
                }
                // Adjust horizontally if necessary
                if (tipStart <= 0) {
                    result += ' --right';
                }
                if (tipEnd >= window.innerWidth) {
                    result += ' --left';
                }
            }

            this.position = result;
        },
        onClickOutside() {
            this.showContent = false;
        },
    },
}; </script>