<template>
    <div
        :id="id"
        class="lni-c-multiselect"
        :class="{
            '--filled': filled,
            '--outlined': !filled,
            '--dense': dense,
            '--label-before': labelBefore,
            '--activated': isActivated,
            '--has-error': hasErrorText,
        }"
        @focus="focusOnInput"
    >
        <div class="lni-c-multiselect__layout-wrapper">
            <div class="lni-multiselect__widget-wrapper">
                <div
                    role="combobox"
                    :aria-owns="`${id}_listbox`"
                    :aria-expanded="isOpen ? 'true': 'false'"
                    aria-haspopup="listbox"
                >
                    <span
                        v-if="selectedOptions.length"
                        class="lni-c-multiselect__selection-count"
                    > {{ selectedOptions.length }} selected</span>
                    <input
                        :id="`${id}_input`"
                        ref="input"
                        type="text"
                        class="lni-c-multiselect__input lni-c-text-field__field"
                        aria-autocomplete="list"
                        :aria-describedby="`${id}_helper-text`"
                        readonly
                        :placeholder="placeholder"
                        @input="onInput($event)"
                        @keydown="onInputKeydown($event)"
                        @click="onInputClick()"
                        @blur="onInputBlur()"
                    >
                    <label
                        :id="`${id}_label`"
                        :for="`${id}_input`"
                        class="lni-c-multiselect__label lni-c-text-field__label"
                    >{{ labelText }}</label>
                    <span
                        aria-hidden="true"
                        class="lni-c-select__icon"
                        :class="`lnicon--triangle--${isOpen? 'up':'down'}`"
                    />
                    <div class="lni-c-text-field__indicator" />
                </div>
                <div
                    :id="`${id}_listbox`"
                    ref="listbox"
                    :class="{ '--open': isOpen }"
                    class="lni-c-multiselect__listbox"
                    role="listbox"
                    aria-multiselectable="true"
                    tabindex="-1"
                    @mousedown.self="onListMousedown"
                    @mouseup.self="onListMouseup"
                    @transitionend="afterOpenTransition"
                >
                    <div
                        v-for="(option) in optionsState"
                        :id="`${id}_option${option.id}`"
                        :key="option.value"
                        ref="option"
                        role="option"
                        class="lni-c-multiselect__option"
                        :class="{
                            '--active': option.active,
                            '--selected': option.selected,
                            'lni-c-multiselect__select-all': selectAllLabel && option.id === 0,
                        }"
                        :aria-selected="option.selected"
                        :aria-disabled="option.disabled"
                        @mousedown="onOptionMousedown"
                        @click="onOptionClick(option)"
                    >
                        <div
                            class="lni-c-multiselect__option-state-indicator"
                            :class="{'--indeterminate': option.selected === undefined}"
                            aria-hidden="true"
                        />
                        <span class="lni-c-multiselect__option-text">
                            {{ option.text }} {{ countText(option) }}
                        </span>
                    </div>
                </div>
                <div
                    v-if="hasInlineMessages || hasHelperText"
                    :id="`${id}_helper-text`"
                    class="lni-c-contextual-help__wrapper lni-u-type--xxs lni-u-line-height--tight"
                    :class="hasErrorText ? 'lni-c-fieldset__error-message' : 'lni-c-fieldset__helper-text'"
                >
                    <template v-if="hasErrorText">
                        <span
                            class="lni-u-font-color--error"
                            role="alert"
                        >
                            <span
                                aria-hidden="true"
                                class="lnicon--exclamation"
                            />
                            {{ errorText }}
                        </span>
                    </template>
                    <template v-if="!hasErrorText">
                        <slot name="helperText" />
                    </template>
                </div><!-- end helper text -->
            </div>
        </div>
    </div>
</template>
<script>

const KEYS = {
    Down: 'ArrowDown',
    End: 'End',
    Enter: 'Enter',
    Escape: 'Escape',
    Home: 'Home',
    Left: 'ArrowLeft',
    PageDown: 'PageDown',
    PageUp: 'PageUp',
    Right: 'ArrowRight',
    Space: ' ',
    Tab: 'Tab',
    Up: 'ArrowUp',
};
const ACTIONS = {
    Close: 0,
    CloseSelect: 1,
    First: 2,
    Last: 3,
    Next: 4,
    Open: 5,
    Previous: 6,
    Select: 7,
    Space: 8,
    Type: 9,
};
export default {
    name: 'LniMultiselect',
    data() {
        return {
            optionsState: [],
            selectedOptions: [],
            ignoreBlur: false,
            skipOptionsUpdate: false,
        };
    },
    computed: {
        // Track the index for highlighting aria-activedescendent
        activeIndex() {
            const index = this.optionsState.findIndex(option => option.active);
            return index > -1 ? index : undefined;
        },
        activeOption() {
            return this.optionsState[this.activeIndex];
        },
        activeDescendent() {
            return this.activeOption ? `${this.id}_option${this.activeOption.id}` : '';
        },
        optionsAreScrollable() {
            // true if options are taller than their container
            const listbox = this.$refs.listbox;
            return listbox && listbox.clientHeight < listbox.scrollHeight;
        },

        // used to handle "select all" state
        amountSelected() {
            const selected = this.selectedOptions.length;
            const selectable = this.optionsState.filter(opt => !opt.disabled && opt.id !== 0).length;
            if (selected === selectable) {
                return 'all';
            } else if (selected === 0) {
                return 'none';
            }
            return 'some';
        },
        selectionsAsText() {
            return this.selectedOptions.map(opt => opt.text).join(', ');
        },
        isActivated() {
            // true if the menu is open
            return this.isOpen
            // the input has placeholder text
            || this.placeholder.length
            // the input is focused
            || this.$refs.input === document.activeElement
            // or selections are shown inline
            || this.selectionsAsText;
        },
        hasErrorText() {
            return !!this.$store.state[this.id].errorText;
        },
        hasHelperText() {
            return !!this.$slots.helperText;
        },
        hasSelection() {
            return !!this.value;
        },
    },
    mounted() {
        this.$watch('value', () => {
            if (this.skipOptionsUpdate) {
                // value is updated via ui
                this.skipOptionsUpdate = false;
                this.$nextTick(this.onChange);
                return;
            }
            // update optionsState and selectedOptions to match value
            this.$nextTick(() => this.setSelectedFromValue());
        });
        this.$watch('options', (newVal, oldVal) => {
            if (newVal !== oldVal) {
                // a new object is being set
                this.initializeOptionsState();
            }
        });
        this.initializeOptionsState();
        this.updateValidity();
    },
    methods: {
        /* Set optionsState so we can keep track of dynamic state without mutating options */
        initializeOptionsState() {
            const opts = [...this.options];
            if (this.selectAllLabel) {
                // add select all as first option
                const selectAllOption = {
                    text: this.selectAllLabel,
                };
                opts.unshift(selectAllOption);
            }
            const isSelected = opt => this.value.includes(opt.value);
            this.optionsState = opts.map((option, index) => ({
                ...option,
                id: index,
                selected: isSelected(option),
                active: false,
            }));
        },
        setSelectedFromValue() {
            // clear selections
            const opts = [...this.optionsState].map(opt => ({
                ...opt,
                selected: false,
            }));
            this.selectedOptions = [];

            if (opts.length) {
                [...this.optionsState].forEach(opt => {
                    if (this.value.includes(opt.value)) {
                        this.toggleSingleOption(opt, true);
                    } else {
                        this.toggleSingleOption(opt, false);
                    }
                });
            } else {
                console.warn(`The value cannot be set on ${this.id} because options do not exist`);
            }
        },
        // Helpers for managing optionsState
        getOptionIndex(option) {
            return this.optionsState.findIndex(opt => opt.id === option.id);
        },
        getOption(option) {
            return this.optionsState[this.getOptionIndex(option)];
        },
        // Manage activeDescendent
        setActiveOption(index) {
            this.unsetActiveOption();

            if (this.optionsState.length) {
                this.getOption(this.optionsState[index]).active = true;
            }
            //trigger reactivity
            this.optionsState = [...this.optionsState];
            this.$refs.input.setAttribute('aria-activedescendant', `${this.activeDescendent}`);
            if (this.optionsAreScrollable) {
                this.$nextTick(() => this.scrollToActiveOption());
            }
        },
        unsetActiveOption() {
            if (!this.activeOption) {
                return;
            }
            this.optionsState[this.activeIndex].active = false;
            //trigger reactivity
            this.optionsState = [...this.optionsState];
        },
        // Depending on the action update the index of the active option
        getUpdatedIndex(action) {
            const last = this.optionsState.length - 1;
            switch (action) {
                case ACTIONS.Open:
                    return 0;
                case ACTIONS.First:
                    return 0;
                case ACTIONS.Last:
                    return last;
                case ACTIONS.Previous:
                    // decrement unless we are at 0 or set to zero
                    return this.activeIndex === undefined ? 0 : Math.max(0, this.activeIndex - 1);
                case ACTIONS.Next:
                    if (this.activeIndex === undefined) {
                        return 0;
                    }
                    // increment unless we are at the end
                    return Math.min(last, this.activeIndex + 1);
                default:
                    return undefined;
            }
        },
        // Manage keyboard commands
        actionFromKey(key) {

            // handle opening when closed
            if (!this.isOpen && [KEYS.Down, KEYS.Space].includes(key)) {
                return ACTIONS.Open;
            }

            // handle keys when open
            if (key === KEYS.Down) {
                return ACTIONS.Next;
            } else if (key === KEYS.Up) {
                return ACTIONS.Previous;
            } else if (key === KEYS.Home) {
                return ACTIONS.First;
            } else if (key === KEYS.End) {
                return ACTIONS.Last;
            } else if (key === KEYS.Escape) {
                return ACTIONS.Close;
            } else if (key === KEYS.Enter) {
                return ACTIONS.Select;
            } else if (key === KEYS.Space) {
                return ACTIONS.Space;
            }
            // Type means just pass the keydown event to the input
            return ACTIONS.Type;
        },
        // Events
        onInputKeydown(event) {
            const action = this.actionFromKey(event.key);
            switch (action) {
                case ACTIONS.Next:
                case ACTIONS.Last:
                case ACTIONS.First:
                case ACTIONS.Previous:
                    event.preventDefault();
                    if (!this.isOpen) {
                        this.isOpen = true;
                    }
                    this.setActiveOption(this.getUpdatedIndex(action));
                    break;
                case ACTIONS.Select:
                    event.preventDefault();
                    if (this.activeIndex === undefined  || this.activeOption.disabled) {
                        return;
                    }
                    this.toggleOption(this.activeOption);
                    break;
                case ACTIONS.Close:
                    event.preventDefault();
                    this.unsetActiveOption();
                    this.isOpen = false;
                    break;
                case ACTIONS.Open:
                    event.preventDefault();
                    this.isOpen = true;
                    this.setActiveOption(this.getUpdatedIndex(action));
                    break;
                case ACTIONS.Space:
                    event.preventDefault();
                    break;
                case ACTIONS.Type:
                    break;
                default:
            }
        },
        onInputClick() {
            this.isOpen = !this.isOpen;
            this.setActiveOption(0);
        },
        onInputBlur() {
            if (this.ignoreBlur) {
                this.ignoreBlur = false;
                return;
            }
            this.unsetActiveOption();
            if (this.isOpen) {
                this.isOpen = false;
            }
        },
        onInput(event) {
            this.inputValue = event.target.value;
            if (this.optionsState.length) {
                this.isOpen = true;
                this.setActiveOption(0);
            }
        },
        onListMouseup() {
            this.$refs.input.focus();
        },
        onListMousedown($event) {
            if ($event.offsetX > $event.target.clientWidth || $event.offsetY > $event.target.clientHeight) {
                this.ignoreBlur = true;
            }
        },
        afterOpenTransition() {
            if (this.optionsAreScrollable) {
                this.scrollToActiveOption();
            }
        },
        onOptionMousedown() {
            // prevent focus from leaving input when clicking options
            // reset in onInputBlur event (mousedown > blur > click)
            this.ignoreBlur = true;
        },
        onOptionClick(option) {
            if (!option.disabled) {
                this.setActiveOption(this.getOptionIndex(option));
                this.toggleOption(option);
            }
            this.$refs.input.focus();
        },
        // Select and unselect options
        toggleOption(option, value) {
            /* Both option clicks and keyboard selections hit this first. */
            this.skipOptionsUpdate = true;
            if (this.selectAllLabel && option.id === 0) {
                this.onSelectAll();
            } else {
                this.toggleSingleOption(option, value);
            }
        },
        /* Toggles the selected state in optionState and adds option to selectedOptions */
        /* Value is optional. */
        toggleSingleOption(option, value) {
            const shouldBeSelected = value === undefined ? !option.selected : value;
            const index = this.optionsState.findIndex(opt =>
                opt.id === option.id || opt.value === option.value);
            if (shouldBeSelected) {
                this.select(index);
            } else {
                this.unselect(index);
            }

            if (this.selectAllLabel) {
                this.$nextTick(() => this.handleSelectAllState());
            }

            //update input to show value as comma separated text
            this.$refs.input.value = this.selectionsAsText;

            if (this.skipOptionsUpdate) {
                this.$nextTick(this.updateValue);
            }
        },
        select(index) {
            this.$set(this.optionsState, index, {
                ...this.optionsState[index],
                selected: true,
            });

            this.selectedOptions.push({
                ...this.optionsState[index],
            });
        },
        unselect(index) {
            this.$set(this.optionsState, index, {
                ...this.optionsState[index],
                selected: false,
            });
            const indexInSelected = this.selectedOptions.findIndex(
                opt => opt.id === this.optionsState[index].id,
            );
            if (indexInSelected > -1 ) {
                this.selectedOptions.splice(indexInSelected, 1);
            }
        },
        updateValue() {
            const correctValue = [...this.selectedOptions].map(opt => opt.value);
            this.value = correctValue;
        },
        // Select all
        onSelectAll() {
            if (this.amountSelected === 'all') {
                this.toggleAllOptions(false);
            } else {
                // amountSelected = 'some' or 'none'
                this.toggleAllOptions(true);
            }
        },
        toggleAllOptions(value) {
            this.optionsState.forEach(opt => {
                if (opt.id !== 0 && opt.selected !== value) {
                    this.toggleSingleOption(opt, value);
                }
            });
        },
        handleSelectAllState() {
            let selected; // true, false, or undefined
            if (this.amountSelected === 'all') {
                selected = true;
            } else if (this.amountSelected === 'none') {
                selected = false;
            }
            this.$set(this.optionsState, 0, {
                ...this.optionsState[0],
                selected,
            });
        },
        /* keep the active option visible */
        scrollToActiveOption() {
            if (this.activeIndex === undefined) {
                //there isn't an active option
                return;
            }
            const activeElement = this.$refs.option[this.activeIndex];
            const listbox = this.$refs.listbox;

            const {
                offsetHeight,
                offsetTop,
            } = activeElement;
            const {
                offsetHeight: parentOffsetHeight,
                scrollTop,
            } = listbox;

            const isAbove = offsetTop < scrollTop;
            const isBelow = (offsetTop + offsetHeight) > (scrollTop + parentOffsetHeight);

            if (isAbove) {
                listbox.scrollTo(0, offsetTop);
            } else if (isBelow) {
                listbox.scrollTo(0, offsetTop - parentOffsetHeight + offsetHeight);
            }
        },
        updateValidity() {
            return this.$store.dispatch(`updateValidity`, {
                targetId: this.id,
                validity: {
                    isValid: !!this.value,
                    valueMissing: !this.value,
                },
            });
        },
        onChange() {
            this.$store.commit(`${this.id}/setDirty`, {
                value: true,
            });
            this.updateValidity().then(() => {
                if (this.$store.state[this.id].flags.dirty && this.changeValidationAction) {
                    this.dispatchEvent('changeValidationAction');
                }
            });

            this.dispatchEvent('changeAction');
            this.$emit('change');

        },
        countText(option) {
            if (option.count || typeof option.count === 'number') {
                return `(${option.count})`;
            }
            return '';
        },
        focusOnInput() {
            this.$refs.input.focus();
        },
    },
}; </script>