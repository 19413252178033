<template>
    <div
        class="lni-c-text-field"
        :class="{
            '--required': required,
            [`--custom-width-${customWidth}`]: customWidth,
            '--dense': dense,
            'lni-u-full-width': fullWidth,
            '--has-prefix': hasPrefix,
            '--has-suffix': hasSuffix,
            '--has-leading-icon': hasLeadingIcon,
            '--has-trailing-icon': hasTrailingIcon || hasErrorText,
            '--filled': filled,
            '--min-content': customWidth,
            '--outlined': !filled,
            '--activated': isActivated,
            '--label-before': labelBefore,
            '--has-error': hasErrorText,
            '--has-helper-text': hasHelperText,
        }"
    >
        <div class="lni-c-input-text__input-suffix-wrapper">
            <component
                :is="formElement"
                :id="id"
                ref="input"

                :name="calculatedName"
                :required="required"
                :minlength="minlength"
                :maxlength="maxlength"
                :min="min"
                :max="max"
                :pattern="pattern"
                :disabled="disabled"
                :type="htmlInputType === 'number' ? 'text' : htmlInputType"
                :value="value"
                placeholder=" "
                class="lni-c-text-field__field lni-u-full-width"
                :aria-describedby="describedBy"
                :aria-labelledby="ariaLabelledby"
                :autocomplete="autocomplete"
                :aria-autocomplete="ariaAutocomplete"
                :aria-controls="ariaControls"
                :aria-activedescendant="ariaActivedescendant"
                @keydown="$emit('keydown', $event)"
                @focus="$emit('focus', $event)"
                @change="onChange"
                @input="onInput"
                @keydown.enter="onEnter"
                @blur="onBlur"
                @paste="onPaste"
            />
            <div
                v-if="hasSuffix"
                :id="`${id}_suffix`"
                class="lni-c-text-field__suffix"
            >
                <slot name="suffix" />
            </div>
        </div>
        <label
            :for="id"
            class="lni-c-text-field__label"
        >{{ labelText }}</label>
        <div
            v-if="hasPrefix"
            class="lni-c-text-field__prefix"
        >
            <slot name="prefix" />
        </div>
        <div
            v-if="hasLeadingIcon"
            class="lni-c-text-field__leading-icon lni-u-text--center"
        >
            <slot name="leadingIcon">
                <span
                    :aria-hidden="true"
                    :class="`lnicon--${leadingIcon}`"
                />
            </slot>
        </div>
        <!--
        key is required to trigger lifecycle methods for components hidden by defualt
        https://github.com/vuejs/vue/issues/4205
    -->
        <div
            v-if="hasErrorText"
            class="lni-c-text-field__trailing-icon lni-u-text--center"
        >
            <span
                key="trailing-icon-error"
                :aria-hidden="true"
                class="lnicon--exclamation"
            />
        </div>
        <div
            v-else-if="hasTrailingIcon"
            :class="['lni-c-text-field__trailing-icon',
                     'lni-u-text--center',
                     { 'lni-u-cursor-pointer': trailingIconIsClickable },
            ]"
        >
            <span
                key="trailing-icon-normal"
                :aria-hidden="true"
                :class="`lnicon--${trailingIcon}`"
                @click="$emit('trailingIconClick')"
            />
        </div>

        <div class="lni-c-text-field__indicator" />
        <slot name="after-label" />
        <div
            v-if="hasInlineMessages && (hasHelperText || hasErrorText)"
            :id="`${id}_helper-text`"
            class="lni-c-text-field__helper-text lni-u-type--xxs lni-u-line-height--tight"
        >
            <template v-if="!hasErrorText || persistHelperText">
                <slot name="helperText" />
            </template>
            <template v-if="hasErrorText">
                <p
                    :id="`${id}_error-text`"
                    class="lni-c-text-field__error-message"
                >
                    {{ errorText }}
                </p>
            </template>
        </div>
    </div>
</template>

<script>
const validInputTypes = {
    null: true,
    email: true,
    number: true,
    currency: true,
    password: true,
    search: true,
    tel: true,
    text: true,
    url: true,
};
const currencyDecimals = 2;

export default {
    name: 'LniInputText',
    computed: {
        calculatedName() {
            // This is for when we actually want no name on the input.
            // The use case for this is for when the form is submitted and we don't want this input
            // submitted.
            if (this.name === 'no-name') {
                return '';
            }

            if (!this.name) {
                return this.id;
            }

            return this.name;
        },
        isActivated() {
            if (this.value && this.value.length) {
                return true;
            }
            return false;
        },
        hasErrorText() {
            return !!this.$store.state[this.id].errorText;
        },
        hasHelperText() {
            return !!this.$slots.helperText;
        },
        hasLeadingIcon() {
            return !!this.$store.state[this.id].leadingIcon;
        },
        hasTrailingIcon() {
            return !!this.$store.state[this.id].trailingIcon;
        },
        hasSuffix() {
            return !!this.$slots.suffix;
        },
        hasPrefix() {
            return !!this.$slots.prefix;
        },
        describedBy() {
            let ids = '';
            if (this.ariaDescribedby) {
                ids += `${this.ariaDescribedby}`;
            }
            if (this.hasHelperText) {
                ids += `${ids.length > 0 ? ' ' : ''}${this.id}_helper-text`;
            }
            if (this.hasErrorText) {
                ids += `${ids.length > 0 ? ' ' : ''}${this.id}_error-text`;
            }

            if (!ids.length) {
                return false;
            }
            return ids;
        },
        htmlInputType: {
            get() {
                return this.$store.state[this.id].type === 'currency'
                    ? 'text'
                    : this.$store.state[this.id].type;
            },
        },
        type: {
            get() {
                return this.$store.state[this.id].type;
            },
            set(value) {
                if (!!value && !validInputTypes[value]) {

                    console.warn('Please use a valid input type (null, email, number, currency, password, search, tel, text, url)'); // eslint-disable-line max-len
                } else {
                    this.$store.commit('setAttribute', {
                        id: this.id,
                        attribute: 'type',
                        value,
                    });
                }
            },
        },
    },
    mounted() {
        this.updateValidity();
    },
    updated() {
        this.setInputValue();
        this.updateValidity();
        this.$emit('updated');
    },
    methods: {
        setInputValue() {
            //Hack to resolve issues with the "<component>" component
            //TODO: Find a better way.
            const element = document.getElementById(this.id);
            if (element) {
                element.value = this.value;
            }

            // Raising this event so that parent components can respond to an updated value.
            //  This is required to make the mask for phone work with autocomplete
            this.$emit('valueSet');
        },
        setValue(text) {
            this.value = this.type === 'currency'
                ? this.formatCurrency(text)
                : text;
            this.setInputValue();
        },
        currencyHasExtraDecimalChars(value) {
            const decimalIndex = value.indexOf('.');
            if (decimalIndex !== -1) {
                return value.length - decimalIndex > currencyDecimals;
            }

            return false;
        },
        formatCurrency(value) {
            const standardizedValue = value.replace(',', '');
            if (this.currencyHasExtraDecimalChars(standardizedValue)) {
                const parsedNumber = Number.parseFloat(standardizedValue);
                return isNaN(parsedNumber)
                    ? standardizedValue
                    : parsedNumber.toFixed(currencyDecimals);
            }
            return standardizedValue;
        },
        checkMinimalLength(val) {
            return val.length < this.minlength;
        },
        checkEmailFormat(value){
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return this.type === 'email' && !emailRegex.test(value) && value.length > 0;
        },
        copyValidityState(validityState) {
            const validityStore = {};
            for (let type in validityState) {
                if (validityState) {
                    validityStore[type] = validityState[type];
                }
            }
            return validityStore;
        },
        updateValidity() {
            const validity = this.copyValidityState(this.$refs.input.validity);

            validity.tooShort = this.checkMinimalLength(this.value);
            validity.typeMismatch = this.checkEmailFormat(this.value)

            return this.$store.dispatch('updateValidity', {
                targetId: this.id,
                validity,
            });
        },
        onInput(event) {
            if (this.htmlInputType === 'number') {
                const patternRegex = new RegExp(/^-?\d*\.?\d*$/); // Number format -#.#
                if (!this.pattern && !patternRegex.test(event.target.value)) {
                    const caretPos = event.srcElement.selectionStart - 1;
                    if (event.inputType !== 'deleteContentBackward') {
                        event.target.value = this.value;
                    }
                    // Prevent cursor from jumping to the end
                    event.srcElement.setSelectionRange(caretPos, caretPos);
                    return;
                }
            }
            // Restrict input to max length
            if (event.target.value && this.maxlength) {
                const maxLengthNum = parseInt(this.maxlength);
                const valueString = event.target.value.toString();
                if (!isNaN(maxLengthNum) && valueString.length > maxLengthNum) {
                    event.target.value = this.value;
                    return;
                }
            }

            this.setValue(event.target.value);
            this.$store.commit(`${this.id}/setDirty`, {
                value: true,
            });

            this.updateValidity().then(() => {
                if (this.hasErrorText && this.inputValidationAction) {
                    this.dispatchEvent('inputValidationAction');
                }

                this.dispatchEvent('inputAction');
            });

            this.$emit('updated');
            this.$emit('input');
        },
        onChange(event) {
            this.setValue(event.target.value);

            this.checkMinimalLength(event);
            this.checkEmailFormat(event)

            if (this.$store.state[this.id].flags.dirty && this.changeValidationAction) {
                this.dispatchEvent('changeValidationAction');
            }
            this.dispatchEvent('changeAction');
        },
        onEnter() {
            this.dispatchEvent('enterAction');
        },
        onPaste() {
            this.$store.commit(`${this.id}/setDirty`, {
                value: true,
            });

            this.updateValidity().then(() => {
                if (this.hasErrorText && this.inputValidationAction) {
                    this.dispatchEvent('inputValidationAction');
                }

                this.dispatchEvent('pasteAction');
            });

            this.$emit('paste');
        },
        onBlur($event) {
            if (this.$store.state[this.id].flags.dirty && this.blurValidationAction) {
                this.dispatchEvent('blurValidationAction');
            }

            this.dispatchEvent('blurAction');

            this.$emit('blur', $event);
        },
    },
}; </script>