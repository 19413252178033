import components from '@gov.wa.lni/component.lni-table/components';

export default {
    name: 'lni-table',
    components: {
        'table-message': components.TableMessage,
        'table-caption': components.TableCaption,
        'table-header': components.TableHeader,
        'table-body': components.TableBody,
    },
    mounted() {
        this.addStateWatchers();
    },
    render(createElement) {
        return createElement('div', {
            class: [
                'lni-c-table-container',
                {
                    '--placeholder': this.loadState !== 'loaded',
                },
            ],
        }, [this.renderContent(createElement)]);
    },
    methods: {
        addStateWatchers() {
            this.$watch('pageSize', function updateState() {
                this.page = 1;
                this.refreshData();
                this.updatePager();
            });
            this.$watch('items', function updateTableState() {
                this.setData();
                this.updatePager();
            });
        },
        updatePager() {
            if (!this.hidePagingActions && this.$store.state[`${this.id}_pager`]) {
                this.$store.commit('setAttribute', {
                    id: `${this.id}_pager`,
                    attribute: 'totalItems',
                    value: this.totalItems,
                }, {
                    root: true,
                });

                this.$store.commit('setAttribute', {
                    id: `${this.id}_pager`,
                    attribute: 'pageSize',
                    value: this.pageSize,
                }, {
                    root: true,
                });

                this.$store.commit('setAttribute', {
                    id: `${this.id}_pager`,
                    attribute: 'page',
                    value: this.page,
                }, {
                    root: true,
                });
            }
        },
        setData() {
            if (this.inPlacePagingAndSorting && this.totalItems !== 0) {
                this.updateInPlaceData(this);
                this.itemsAreLoaded = true;
            } else {
                this.currentPageOfItems = this.items;
                if (!this.hidePagingActions) {
                    this.updatePager();
                }
            }
            this.setSortIcons();

            this.setDisplayedCaption();
        },
        refreshData() {
            if (this.inPlacePagingAndSorting && this.totalItems !== 0) {
                if (this.itemsAreLoaded) {
                    this.updateInPlaceData();
                } else {
                    this.dispatchEvent('setDataAction')
                        .then(() => {
                            this.updateInPlaceData();
                            this.itemsAreLoaded = true;
                        });
                }
            } else {
                this.dispatchEvent('setDataAction');
            }

            this.setDisplayedCaption();
        },
        updateInPlaceData() {
            this.$store.dispatch(`${this.id}/sortAndPageItems`, {
                targetId: this.id,
            });
        },
        getSortingCaption() {
            let sortKeys = Object.keys(this.sort);
            if (sortKeys.length === 0) {
                return 'Not Sorted';
            }

            const sortField = sortKeys[0];
            const sortDirection = this.sort[sortField];

            let column = this.columns.find(c => c.source === sortField);
            if (!column) {
                return 'Not Sorted';
            }

            const sortDirectionDisplayText = sortDirection === 'asc' ? 'ascending' : 'descending';
            return `Sorted by: ${column.displayText} ${sortDirectionDisplayText}`;
        },
        setDisplayedCaption() {
            const sortingCaptionTimeout = 2000;
            const sortingCaption = this.getSortingCaption();
            this.displayedCaption = `${this.caption} ${sortingCaption}`;
            if (this.$refs.SortingCaption) {
                this.$refs.SortingCaption.innerText = sortingCaption;
                setTimeout(() => {
                    if (this.$refs
                        && this.$refs.SortingCaption
                        && this.$refs.SortingCaption.innerText === sortingCaption) {
                        this.$refs.SortingCaption.innerText = '';
                    }
                }, sortingCaptionTimeout);
            }
        },
        setSortingPropertiesFromColumnData(data) {
            let columnSource = data.columnSource;
            let currentSortDirection = this.sort[columnSource];
            let newSortDirection = 'asc';
            if (currentSortDirection && currentSortDirection === 'asc') {
                newSortDirection = 'desc';
            }

            Object.keys(this.sort).forEach(key => {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete this.sort[key];
            });
            this.sort[columnSource] = newSortDirection;

            this.setDisplayedCaption();
        },
        setSortIcons() {
            this.columns.forEach(c => {
                if (c.sortable) {
                    let sortIconName = '';
                    let sortDirection = this.sort[c.source];
                    if (sortDirection) {
                        sortIconName = sortDirection === 'asc' ? 'caret--up' : 'caret--down';
                    }
                    const headerColumnSortIcon = document.getElementById(`${this.id}__sortIcon-${c.source}`);
                    if (headerColumnSortIcon) {
                        headerColumnSortIcon.classList
                            .remove('lnicon--', 'lnicon--caret--up', 'lnicon--caret--down');
                    }
                    if (sortIconName && headerColumnSortIcon) {
                        headerColumnSortIcon.classList.add(`lnicon--${sortIconName}`);
                    }
                }
            });
        },
        onSort(data) {
            this.setSortingPropertiesFromColumnData(data);
            this.setSortIcons();
            this.refreshData();
        },
        onChangePage(data) {
            this.page = data.page;
            this.refreshData();
        },
        setSuccessfulLoadState() {
            if (this.totalItems === 0 && this.loadState === 'loaded') {
                this.loadState = 'no-data';
            } else if (this.totalItems !== 0
                && (this.loadState === 'no-data'
                    || this.loadState === 'initial'
                    || this.loadState === '')) {
                this.loadState = 'loaded';
            }
        },
        renderContent(createElement) {
            this.setSuccessfulLoadState();
            switch (this.loadState) {
                case 'initial':
                case 'no-data':
                case 'warn':
                case 'info':
                case 'error': return this.renderMessage(createElement);
                case 'reset': return this.renderNothing();
                case 'loading': return this.renderLoading(createElement);
                case 'loaded': return this.renderLoaded(createElement);
                default: return this.renderMessage(createElement);
            }
        },
        renderNothing() {
            return '';
        },
        renderMessage(createElement) {
            return createElement('table-message', {
                attrs: {
                    state: this.loadState,
                    noRowsMessage: this.noRowsMessage,
                    errorMessage: this.errorMessage,
                    customMessage: this.customMessage,
                },
            });
        },
        renderLoading(createElement) {
            return createElement('transition', {
                attrs: {
                    name: 'lni-o-fade',
                },
            }, [
                createElement('lni-spinner', {
                    attrs: {
                        id: this.id + '_spinner',
                    },
                    class: [
                        '--small',
                        '--overlay',
                        'lni-u-flex',
                    ],
                }),
            ],
            );
        },
        renderLoaded(createElement) {
            // eslint-disable-next-line consistent-this
            let vm = this;
            const table = createElement(
                'table',
                {
                    attrs: {
                        summary: this.summary,
                        'aria-describedby': `${this.id}_caption`,
                    },
                    class: ['lni-c-table',
                        {
                            'lni-c-basic-table': this.striped === false,
                            'lni-c-zebra-table': this.striped === true,
                        },
                    ],
                },
                [
                    createElement('table-caption', {
                        attrs: {
                            id: `${this.id}_caption`,
                            showCaption: this.showCaption,
                            displayedCaption: this.displayedCaption,
                            class: `lni-c-table__caption lni-u-heading--3 ${this.showCaption ? '' : 'lni-u-visually-hidden'}`,
                        },
                    }),
                    createElement('table-header', {
                        attrs: {
                            tableId: this.id,
                            columns: this.columns,
                            sort: this.sort,
                        },
                        on: {
                            sort: this.onSort,
                        },
                    }),
                    createElement('table-body', {
                        attrs: {
                            currentPageOfItems: this.currentPageOfItems,
                        },
                        scopedSlots: {
                            body(props) {
                                return vm.$scopedSlots.body(props);
                            },
                        },
                    }),
                ],
            );

            let tableWrapper = createElement(
                'div',
                {
                    class: 'lni-c-table__wrapper',
                    attrs: {
                        tabIndex: '0',
                        role: 'region',
                        'aria-labelledby': `${this.id}_caption`,
                    },
                },
                [table],
            );

            let tableAndPagination = createElement(
                'div',
                {
                    class: 'lni-c-table__table-and-pagination',
                },
                [
                    tableWrapper,
                    createElement('lni-pager', {
                        attrs: {
                            id: `${this.id}_pager`,
                            collectionId: this.id,
                            page: this.page,
                            pageSize: this.pageSize,
                            options: this.pageSizeOptions,
                            totalItems: this.totalItems,
                            columnCount: this.columns.length,
                            hidePagingActions: this.hidePagingActions,
                            scrollTarget: this.scrollTarget,
                        },
                        on: {
                            'change-page': this.onChangePage,
                        },
                    }),
                    createElement('div', {
                        class: 'lni-u-visually-hidden',
                        ref: 'SortingCaption',
                        attrs: {
                            id: `${this.id}_sorting-caption`,
                            'aria-live': 'polite',
                        },
                    }),
                ],
            );

            return tableAndPagination;
        },

    },
};