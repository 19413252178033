<template>
    <div class="lni-c-fetch__wrapper">
        <transition name="lni-o-fade">
            <div
                v-show="currentState !== 'pending' || !animateFetch">
                <slot
                    :responseData="responseData"
                    :error="error"
                    :currentState="currentState"></slot>
            </div>
        </transition>
        <div
            v-show="currentState === 'pending' && animateFetch"
            class="lni-u-text--center lni-u-full-height">
            <lni-spinner
                :id="`${id}_spinner`"
                :inline="!showOverlaySpinner"></lni-spinner>
        </div>
    </div>
</template>
<script>

export default {
    name: 'lni-fetch',

    mounted() {
        this.$store.subscribe(mutation => {
            if (mutation.type === `${this.id}/CHANGE_STATE`) {
                const current = this.$store.state[this.id].currentState;
                if ( current === 'success' ) {
                    this.dispatchEvent('successAction');
                } else if ( current === 'error' ) {
                    this.dispatchEvent('errorAction');
                }
            }
        });
    },
}; </script>