<template>
    <div
        v-if="isScrolled"
        :id="id"
        role="navigation"
        :aria-label="label"
        class="lni-c-back-to-top">
        <a href="#app">
            <lni-svg-icon
                :id="`${id}_svg`"
                viewBox="0 0 44 52"
                width="44"
                height="52"
                :fill="null"
                :stroke="null">
                <upIcon></upIcon>
                <text
                    v-if="isEnglish"
                    x="8"
                    y="37">TOP</text>
                <text
                    v-else
                    x="8"
                    y="37">TOP</text>
            </lni-svg-icon>
        </a>
    </div>
</template>

<script>
import upIcon from '@gov.wa.lni/component.lni-fixed-action-button/icons/up.vue';

export default {
    name: 'lni-fixed-action-button',
    components: {
        upIcon,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            scrollPos: 0,
        };
    },
    computed: {
        isEnglish() {
            return this.$oneLni.getLanguage() === 'en';
        },
        isScrolled() {
            const qtrScreen = 1.25;
            let scrollHeight = -Math.abs(window.innerHeight * qtrScreen);

            if ((this.scrollPos < 0) && (scrollHeight > this.scrollPos)) {
                return true;
            }
            return false;
        },
    },
    mounted() {
        window.addEventListener('scroll', this.onScrollHandler);
    },
    methods: {
        onScrollHandler() {
            this.scrollPos = document.body.getBoundingClientRect().top;
        },
    },
}; </script>