<template>
    <div
        :id="id"
        class="lni-c-input-password lni-u-flex lni-u-flex-column"
        :class="{
            '--has-error': !$store.getters[`${id}/isReallyValid`] && !$store.getters[`${id}/isValid`],
            '--is-valid': $store.getters[`${id}/isReallyValid`],
            '--new-password': isNewPassword,
        }"
        @input="updateValidity"
        @focus="focusOnInput">
        <lni-input-text
            :id="`${id}_input-text`"
            ref="input"
            :labelText="labelText || 'Password'"
            type="password"
            :autocomplete="isNewPassword ? 'new-password' : 'current-password'"
            :name="name || id"
            :required="required"
            :maxlength="maxlength"
            :minlength="minlength"
            :fullWidth="fullWidth"
            :filled="filled"
            :disabled="disabled"
            :persistHelperText="true"
            :inputValidationAction="inputValidationAction"
            :changeValidationAction="changeValidationAction"
            :blurValidationAction="blurValidationAction"
            :changeAction="changeAction"
            :inputAction="[`${id}/sync`, inputAction]">
            <template slot="helperText">
                <template v-if="isNewPassword">
                    <p class="lni-u-type--bold">
                        For your security, please enter a password with:
                    </p>
                    <ul
                        class="lni-c-input-password__requirements-list"
                        role="list">
                        <li :class="$store.getters[`${id}/tooShort`] ? '' : '--isMet'">
                            <span
                                aria-hidden="true"
                                class="lnicon--checkmark"></span>
                            At least 10 characters
                        </li>
                    </ul>
                    <p class="lni-u-type--bold">
                        including at least 3 of the following:
                    </p>
                    <ul
                        class="lni-c-input-password__requirements-list"
                        role="list">
                        <li :class="$store.getters[`${id}/hasUpperCase`] ? '--isMet' : ''">
                            <span
                                aria-hidden="true"
                                class="lnicon--checkmark"></span>One uppercase letter
                        </li>
                        <li :class="$store.getters[`${id}/hasLowerCase`] ? '--isMet' : ''">
                            <span
                                aria-hidden="true"
                                class="lnicon--checkmark"></span>One lowercase letter
                        </li>
                        <li :class="$store.getters[`${id}/hasNumber`] ? '--isMet' : ''">
                            <span
                                aria-hidden="true"
                                class="lnicon--checkmark"></span>One number
                        </li>
                        <li :class="$store.getters[`${id}/hasSpecialCharacter`] ? '--isMet' : ''">
                            <span
                                aria-hidden="true"
                                class="lnicon--checkmark"></span>One special character
                        </li>
                    </ul>
                    <p class="lni-u-type--bold">
                        Do not include your user ID.
                    </p>
                </template>
                <p
                    v-if="errorText"
                    class="lni-c-input-password__error-text">
                    {{ errorText }}
                </p>
            </template>
            <template
                v-if="!errors.length"
                slot="helperText">
                <div class="lni-c-input-password__message-spacer">
                        &nbsp;
                </div> <!-- preserve space for error message in regular password -->
            </template>
            <template slot="after-label">
                <button
                    type="button"
                    class="lni-c-input-password__visibility-toggle lni-u-flex lni-u-items-center lni-u-gap1"
                    :aria-pressed="passwordVisible ? 'true' : 'false'"
                    @click="togglePasswordVisibility">
                    <span class="lni-u-visually-hidden">Show password</span>
                    <lni-svg-icon
                        v-if="!passwordVisible"
                        :id="`${id}_svg-icon`"
                        :fill="null"
                        :stroke="null">
                        <eyeball-closed></eyeball-closed>
                    </lni-svg-icon>
                    <lni-svg-icon
                        v-else
                        :id="`${id}_svg-icon`"
                        :fill="null"
                        :stroke="null">
                        <eyeball-open></eyeball-open>
                    </lni-svg-icon>
                </button>
            </template>
        </lni-input-text>
    </div>
</template>

<script>
import icons from '@gov.wa.lni/component.lni-input-password/icons';

export default {
    name: 'lni-input-password',
    components: icons,
    inheritAttrs: false,
    data() {
        return {
            passwordVisible: false,
        };
    },
    mounted() {
        this.updateValidity();
    },
    methods: {
        togglePasswordVisibility() {
            const inputID = `${this.id}_input-text`;
            this.passwordVisible = !this.passwordVisible;

            this.$store.commit('setAttribute', {
                id: inputID,
                attribute: 'type',
                value: this.passwordVisible ? 'text' : 'password',
            }, {
                root: true,
            });
        },
        updateValidity() {
            return this.$store.dispatch(`${this.id}/customValidate`, {
                targetId: this.id,
            });
        },
        validate() {
            this.updateValidity();
        },
        focusOnInput() {
            this.$refs.input.$refs.input.focus();
        },
    },
}; </script>