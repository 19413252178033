const executeAfterMinimumDelay = (callback, delay, start) => {
    const elapsed =  new Date() - start;
    const timeLeft = delay - elapsed;
    setTimeout(callback, timeLeft > 0 ? timeLeft : 0);
};

export default function sendRequest({
    state, dispatch, getters,
}, payload) {
    const start = new Date();
    if (state.currentState === 'pending') {
        // prevent sending multiple requests
        return;
    }
    dispatch('setError', '');
    fetch(getters.requestUrl(payload.oneLni), state.fetchOptions).then(response  => {
        if (!response.ok) {
            throw new Error(`The network response was not ok.`);
        }
        return response.json();
    }).then(data => {
        const setData = () => dispatch('setResponseData', data);
        executeAfterMinimumDelay(setData, state.minLoadingTime, start);
    }).catch(error => {
        const setError = () => {
            const errorText = `${error.name}: ${error.message}`;
            console.warn(errorText);
            dispatch('setError', errorText);
        };
        executeAfterMinimumDelay(setError, state.minLoadingTime, start);
    });
}