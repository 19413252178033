import updatePageSize from '@gov.wa.lni/component.lni-pager/actions/updatePageSize.js';
import text from '@gov.wa.lni/framework.one-lni.core/source/lib/getText.js';

export default () => ({
    state: {
        lang: '',
        collectionId: '',
        page: 1,
        pageSize: 10,
        totalItems: 0,
        columnCount: 1,
        showPager: true,
        hidePagingActions: false,
        scrollTarget: '',
        navLabel: state => text(state.lang, ['lni-pager']).navLabel,
        currentPageLabel: state => text(state.lang, ['lni-pager']).currentPageLabel,
        firstPageLabel: state => text(state.lang, ['lni-pager']).firstPageLabel,
        goToPageLabel: state => text(state.lang, ['lni-pager']).goToPageLabel,
        selectLabel: state => text(state.lang, ['lni-pager']).selectLabel,
        previousPageLabel: state => text(state.lang, ['lni-pager']).previousPageLabel,
        nextPageLabel: state => text(state.lang, ['lni-pager']).nextPageLabel,
        ellipsisLabel: state => text(state.lang, ['lni-pager']).ellipsisLabel,
        generateAnchorLinkUrl: false,
        index: '',
        query: '',
        options: [
            {
                text: 5,
                value: 5,
            },
            {
                text: 10,
                value: 10,
            },
            {
                text: 20,
                value: 20,
            },
            {
                text: 50,
                value: 50,
            },
        ],
    },
    actions: {
        updatePageSize,
    },
    mutations: {
        SET_PAGE(state, payload) {
            state.page = parseInt(payload);
        },
        SET_TOTAL_ITEMS(state, payload) {
            state.totalItems = parseInt(payload);
        },
        SET_PAGE_SIZE(state, payload) {
            state.pageSize = parseInt(payload);
        },
    },
    getters: {
        totalPages(state) {
            return Math.ceil(state.totalItems / state.pageSize);
        },
    },
});