export default (context, payload) => {
    const selectId = `${context.state.collectionId}_PageSize`;
    // update pager state
    context.commit('SET_PAGE_SIZE', payload);
    // sync select control
    if (context.rootState[selectId]) {
        context.commit('setAttribute', {
            id: selectId,
            attribute: 'value',
            value: payload,
        }, {
            root: true,
        });
    }
};