export default context => {
    const moduleState = context.state;
    let validity = {};
    if (moduleState.validity) {
        validity = {
            ...moduleState.validity,
        };
    }
    if ( moduleState.required ) {
        validity.valueMissing = !`${moduleState.value}` || !`${moduleState.value}`.length;
    }

    return validity;
};