<template>
    <div
        class="lni-c-file-uploads lni-u-mt2"
    >
        <lni-disclosure
            v-for="(files, documentTypeLabel, index) in filesGroup"
            :id="`${id}_disclosure_${index}`"
            :key="index"
            :isOpen="true"
        >
            <template slot="toggle">
                <span class="lni-c-file-uploads__doc-type-label lni-u-type--bold lni-u-inline-block">
                    {{ documentTypeLabel }}
                </span>
            </template>
            <template slot="content">
                <div

                    class="lni-c-file-uploads__item lni-u-pv1"
                >
                    <div
                        v-for="(file, i) in files"
                        :key="i"
                        class="lni-u-flex"
                    >
                        <div class="lni-u-full-width">
                            <div class="lni-u-type--bold">
                                {{ i + 1 }}. {{ file.FileName }}
                            </div>
                            <div class="lni-u-pb1">
                                {{ file.FileDescription }}
                            </div>
                        </div>
                        <div v-if="allowDelete">
                            <!-- Mimic lni-u-file-upload delete button -->
                            <button
                                class="lni-u-ml2 icon lnicon-remove"
                                type="button"
                                title="Delete"
                                @click="deleteFile(documentTypeLabel, i)"
                            >
                                x<span class="lni-u-visually-hidden">{{ text.delete }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </lni-disclosure>
    </div>
</template>

<script>
export default {
    name: 'LniFileUploads',
    emits:['deleted'],
    computed: {
        filesGroup() {
            return this.$store.getters[`${this.id}/uploadedFilesByGroup`];
        },
    },
    methods: {
        deleteFile(type, index) {
            const fileToDelete = this.filesGroup[type][index];
            const i = this.uploadedFiles.findIndex(file => JSON.stringify(file) === JSON.stringify(fileToDelete));
            const temp = [...this.uploadedFiles];
            temp.splice(i, 1);
            this.$store.commit('setAttribute', {
                id: this.id,
                attribute: 'uploadedFiles',
                value: temp,
            }, {
                root: true,
            });

            this.$emit('deleted', this.uploadedFiles);
        },
    },
}; </script>

<style lang="scss">
@import 'lni-file-uploads';
</style>