export default () => ({
    state: {
        text: 'Button Text',
        classString: '',
        disabled: false,
        type: '',
        clickAction: '',

        errorContainerId: '',
        errorsCollectedFrom: [],
    },
});