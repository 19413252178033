import actions from '@gov.wa.lni/component.lni-checkbox-list/actions';
import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';
import text from '@gov.wa.lni/framework.one-lni.core/source/lib/getText.js';

export default () => ({
    state: {
        lang: '',
        selectAllLabel: null,
        required: false,
        displayCount: false,
        labelText: '',
        options: null,
        value: null,
        changeAction: '',
        isColumn: true,
        hasInlineMessages: true,
        messages: {
            valueMissing: {
                global: state => interpolate(
                    text(state.lang, 'lni-checkbox-list').messages.valueMissing.global, {
                        labelText: state.labelText,
                        legend: state.legend ? `${state.legend} ` : '',
                    },
                ),
                inline: state =>
                    text(state.lang, 'lni-checkbox-list').messages.valueMissing.inline,
            },
        },
        errorText: '', // triggers error state in DOM
        errors: [],
        validity: null,
        flags: {
            dirty: false,
            touched: false,
        },
        changeValidationAction: 'validate',
    },
    actions: {
        ...actions,
    },
});