<script>
export default {
    name: 'lni-sync-url-params',

    mounted() {
        const params = this.getUrlParams();
        if ( params ) {
            this.$store.commit(`${this.id}/SET_PARAMS`, params);
        }
    },
    methods: {
        // Convert params to object
        getUrlParams() {
            const query = window.location.search.substring(1);
            const pairs = query.split('&');
            const params = {};
            if (!query || !pairs.length ) {
                return null;
            }
            pairs.forEach(pair => {
                const keyValue = pair.split('=');
                params[keyValue[0]] = decodeURIComponent(keyValue[1].replace(/\+/g, '%20'));
            });
            return params;
        },
    },
    render() {
        return null;
    },
}; </script>