<template>
    <button
        :id="id"
        class="lni-c-toggle-button lni-c-button lni-u-text--center lni-u-text--all-caps lni-u-nowrap"
        :aria-pressed="pressed ? 'true' : 'false'"
        @click="onClick"
    >
        <span
            v-if="icon"
            class="lni-c-toggle-button__icon"
            :class="`lnicon--${icon}`"
            aria-hidden="true"
        />
        <lni-svg-icon
            v-else
            :id="`${id}_svg-icon`"
            class="lni-c-toggle-button__svg-icon"
        >
            <slot />
        </lni-svg-icon>
        <p
            class="lni-c-toggle-button__title"
        >
            {{ text }}
        </p>
    </button>
</template>

<script>
/**
 *  The lni-button component.
 *  @module components/lni-toggle-button
 */
export default {
    name: 'LniToggleButton',
    methods: {
        onClick() {
            this.$store.commit('setAttribute', {
                id: this.id,
                attribute: 'pressed',
                value: !this.pressed,
            }, {
                root: true,
            });

            this.dispatchEvent('clickAction');
            this.$emit('click', this.text);
        }
    },

}
</script>