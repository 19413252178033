export default () => ({
    state: {
        text: ['lni-file-upload'],
        uploadedFiles: [],
        allowDelete: false,
    },
    getters: {
        uploadedFilesByGroup(state) {
            return state.uploadedFiles?.reduce((documentTypeGroup, file) => {
                const {
                    DocumentTypeLabel,
                } = file;
                documentTypeGroup[DocumentTypeLabel] = documentTypeGroup[DocumentTypeLabel] ?? [];
                documentTypeGroup[DocumentTypeLabel].push(file);
                return documentTypeGroup;
            }, {});
        },
    },
});