import readableList from '@gov.wa.lni/framework.one-lni.core/source/lib/readableList.js';
import text from '@gov.wa.lni/framework.one-lni/source/locale/en.js';
import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';

// This is a custom validation function that allows us to take full control of the
// validation pipeline.
export default (context, payload) => new Promise(resolve => {
    // get the existing validity object for this lni-input-address.
    // It has the custom validation tests
    const moduleState = context.rootState[payload.targetId];
    let validity = {};
    if (moduleState.validity) {
        validity = {
            ...moduleState.validity,
        };
    }

    // In the general update validity action, all of the child components of
    // this compound component have had their errors collected by this component.
    // We can use this list of errors to generate a custom error message for just the
    // address component.  We set 'hasInlineMessages' to false on the child components
    // so that their error messages do not get rendered.
    const blankRequiredFields = moduleState.errors
        .filter(error => error.validationTest === 'valueMissing' && error.id !== payload.targetId)
        .map(error => context.rootState[error.id].labelText);

    validity.valueMissing = blankRequiredFields.length > 0;

    if (validity.valueMissing) {
        // grab the existing message functions defined in the component module.
        const newMessageFunctions = {
            ...moduleState.messages.valueMissing,
        };

        // modify the function for the inline message with the correct information from the
        // collected errors
        const fieldsString = readableList(blankRequiredFields);
        const newMessage = interpolate(text['lni-input-address'].requiredFields, {
            fieldsString,
        });

        newMessageFunctions.inline = () => newMessage;

        // use the following mutation update the message functions on the component store
        context.commit('customizeValidationMessage', {
            validationTest: 'valueMissing',
            ...newMessageFunctions,
        });
    }

    const zipCodeModule = context.rootState[`${payload.targetId}_zip`];
    if (zipCodeModule.validity) {
        validity.badZip = zipCodeModule.validity.patternMismatch;
    }

    // Now we have to have our newly created validity object set to the components store module
    context.dispatch('updateValidity', {
        targetId: payload.targetId,
        validity,
    }, {
        root: true,
    }).then(() => {
        // Now we call the generic validate function on this component which will kick off
        // the regular pipeline to validate itself and those above it.
        context.dispatch('validate', {
            targetId: payload.targetId,
            validation: {
                errorsCollectedBy: moduleState.errorsCollectedBy,
            },
        }, {
            root: true,
        }).then(() => {
            resolve();
        });
    });
});