export default () => ({
    state: {
        params: null,
    },
    actions: {
        setParams({
            commit,
            dispatch,
        }, payload) {
            commit('SET_PARAMS', payload);
            dispatch('setUrlParams');
        },
        setUrlParams({
            getters,
        }) {
            const newUrl = getters.url;
            history.pushState({
                path: newUrl,
            }, document.title, newUrl );
        },
    },
    mutations: {
        SET_PARAMS(state, payload) {
            state.params = payload;
        },
    },
    getters: {
        url: (state, getters) => {
            const urlParts = window.location.href.split('?');
            const url = `${urlParts[0]}?${getters.urlParams}`;
            return url;
        },
        urlParams: state => {
            const params = state.params;
            if (!params) {
                return '';
            }
            return Object.keys(params)
                // remove empty keys
                .filter(key => params[key])
                //encode
                .map(key => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        },
    },
});