<template>
    <lni-fieldset
        :id="`${id}_file-upload-form`"
        class="lni-u-mb3 lni-c-file-upload lni-u-full-width">
        <h3 class="lni-u-mv2">
            {{ title }}
        </h3>
        <slot name="instructions"></slot>
        <div v-show="uploadedFiles.length < fileLimit">
            <lni-file-upload
                :id="`${id}_file-upload`"
                :hasVideos="hasVideos"
                :fileTypes="fileTypes"
                :multiple="multiple"
                :sizeLimit="sizeLimit"
                :fileLimit="fileLimit"
                :required="true"
                :value="undefined"></lni-file-upload>
            <lni-input-textarea
                :id="`${id}_fileDescription`"
                class="lni-u-full-width lni-u-mt3"
                labelText="File Description"
                :required="true"
                :limit="charLimit"
                :filled="true"
                value=""></lni-input-textarea>
            <div>
                <lni-button
                    :id="`${id}_submitFile`"
                    class="lni-u-mv1 --alternate"
                    text="Upload File"
                    :clickAction="['validateForm', uploadAction]"
                    :errorContainerId="`${id}_file-upload-error-container`"></lni-button>
                <div class="lni-u-cb lni-u-mb1 lni-u-mt2">
                    <lni-error-container
                        :id="`${id}_file-upload-error-container`"
                        heading="Before we submit the file:"
                        :errorsCollectedFrom="[
                            `${id}_fileDescription`,
                            `${id}_file-upload`
                        ]"></lni-error-container>
                </div>
                <transition name="announcement">
                    <div
                        v-if="startTimer"
                        role="alert"
                        class="lni-u-mb3">
                        <span class="lni-u-ph1 lni-c-informational-message --info lni-c-alert--error">
                            <span>
                                <span
                                    aria-hidden="true"
                                    class="lnicon--info"></span>
                                {{ text.success }}
                            </span>
                        </span>
                    </div>
                </transition>
                <lni-collapse
                    :id="`${id}_uploadSpinner`"
                    :visible="false"
                    :speed=".8"
                    class="app-box lni-u-mhauto">
                    <lni-element-container
                        :id="`${id}_fileUploadLoading`"
                        state="success"
                        message=""></lni-element-container>
                </lni-collapse>
            </div>
        </div>
        <h3 class="lni-u-mt3">
            Uploaded Files ({{ fileLimit - uploadedFiles.length }} Available Uploads Remaining)
        </h3>
        <div v-if="hasUploadsSlot">
            <slot name="uploads"></slot>
        </div>
        <div v-else>
            <ul
                v-if="files"
                class="lni-u-list-reset lni-u-mt2">
                <li
                    v-for="file in uploadedFiles"
                    :key="file.name"
                    class="lni-u-mv1"
                    :class="{ 'server-request-started': file.uploading }">
                    <div class="lni-u-flex">
                        <span
                            aria-hidden="true"
                            class="lni-c-file-input__checked lnicon--checkmark spin circle">
                        </span>
                        <div class="lni-u-flex lni-u-flex-column">
                            <div class="lni-u-ml2 lni-u-mb1">
                                {{ file.FileName }} ({{ fileSizeConversion(file.FileSize) }}) ―
                            </div>
                            <p class="lni-u-ph2">
                                {{ file.FileDescription }}
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </lni-fieldset>
</template>

<script>
import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';

export default {
    name: 'lni-file-upload-form',
    computed: {
        availableUploads() {
            const available = this.fileLimit - this.uploadedFiles.length;
            return interpolate(this.text.availableUploads, {
                available,
            });
        },
        hasUploadsSlot() {
            return !!this.$slots.uploads;
        },
    },
    methods: {
        fileSizeConversion(bit) {
            const b2mb = 1048576;
            const b2kb = 1024;
            const decimals = 2;
            let megaSize = bit / b2mb;
            let kiloSize = bit / b2kb;

            if (megaSize > 1) {
                return `${parseFloat(megaSize.toFixed(decimals))} MB`;
            }
            return `${parseFloat(kiloSize.toFixed(decimals))} kb`;
        },
    },
}; </script>