import getTableState from '@gov.wa.lni/component.lni-table/actions/getTableState.js';
import sortItems from '@gov.wa.lni/component.lni-table/actions/sortItems.js';

export default (context, payload) => {
    if (!payload.targetId) {
        return;
    }
    context.commit('setAttribute', {
        id: payload.targetId,
        attribute: 'loadedState',
        value: 'loading',
    }, {
        root: true,
    });

    const options = getTableState(context, payload.targetId);
    const allItems = options.items.map(i => ({
        ...i,
    }));

    const sortedItems = sortItems(allItems, options.sortField, options.sortDirection);

    let startItem = (options.page - 1) * options.pageSize;
    let pageOfItems = sortedItems.slice(startItem, startItem + options.pageSize);
    context.commit('setAttribute', {
        id: payload.targetId,
        attribute: 'currentPageOfItems',
        value: pageOfItems,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${context.getters.moduleId}_pager`,
        attribute: 'pageSize',
        value: options.pageSize,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: payload.targetId,
        attribute: 'loadedState',
        value: 'loaded',
    }, {
        root: true,
    });
};