import actions from '@gov.wa.lni/component.lni-input-textarea/actions';

export default () => ({
    state: {
        labelText: 'Please set label-text',
        disabled: false,
        inputAction: '',
        limit: false,
        required: false,
        value: '',
        filled: true,
        labelBefore: false,
        fullWidth: false,
        errorText: '',
        errors: [],
        validity: null,
        flags: {
            dirty: false,
            touched: false,
        },
        inputValidationAction: 'validate',
        changeValidationAction: 'validate',
        blurValidationAction: 'validate',
        errorsCollectedFrom: [
            id => `${id}_input-text`,
        ],
    },
    actions: {
        ...actions,
    },
    getters: {
        length(state) {
            if (state.value) {
                return state.value.length;
            }

            return 0;
        },
        remaining(state, getters) {
            if (!state.limit) {
                return 1;
            }

            return state.limit - getters.length;
        },
    },
});