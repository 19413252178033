export default context => {
    const id = context.getters.moduleId;
    const textInput = context.rootState[`${id}_input-text`];
    context.commit('setAttribute', {
        id,
        attribute: 'value',
        value: textInput.value,
    }, {
        root: true,
    });

};