export default () => ({
    state: {
        content: '',
        labelText: '',
        showLabel: false,
        showContent: false,
        hover: false,
        icon: 'info',
        tip: '',
        showOnHover: false,
        toggleSelf: false,
        direction: 'vertical',
    },
});