<template>
    <input
        :name="name"
        :value="value"
        type="hidden">
</template>

<script>
export default {
    name: 'lni-input-hidden',

    mounted() {
        this.$watch('value', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                const element = document.getElementById(this.id);
                if (element) {
                    element.value = this.value;
                }
            }
        });
    },


}; </script>