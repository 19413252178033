<template>
    <component
        :is="isFieldset ? 'fieldset' : 'div'"
        :id="id"
        :class="{ '--required': required}">
        <legend
            v-if="isFieldset"
            class="lni-c-input-address__legend lni-u-heading--4"
            :class="{'lni-u-font-color--error': hasError}">
            {{ labelText }}
            <span
                v-if="hasError"
                aria-hidden="true"
                class="lnicon--exclamation"></span>
        </legend>
        <div class="lni-u-flex">
            <lni-input-text
                :id="id + '_phone'"
                ref="phone"
                v-mask="{pattern: '###-###-####', separator: '-'}"
                autocomplete="off"
                :value="phoneNumber"
                :labelText="isFieldset ? phoneFieldsetLabelText : labelText"
                type="text"
                :required="required"
                :customWidth="true"
                :hideLabel="true"
                maxlength="12"
                leadingIcon="phone"
                :dense="dense"
                :filled="filled"
                :inputAction="[`${id}/updatePhoneNumber`, inputAction]"
                class="lni-u-mt0"
                :hasInlineMessages="hasInlineMessages"
                :pattern="'\\d{3}[\\-]\\d{3}[\\-]\\d{4}'"
                :labelBefore="labelBefore"
                :changeAction="changeAction"
                @valueSet="onPhoneValueSet"
                @paste="onPaste">
                <span slot="helperText">{{ phoneHelperText }}</span>
            </lni-input-text>
            <lni-input-text
                v-if="hasExtension"
                :id="id + '_ext'"
                ref="ext"
                v-constrain="upTo5Digits"
                :value="phoneExtension"
                :labelText="extensionLabelText"
                :aria-describedby="isFieldset ? null : `${id}_phone`"
                type="tel"
                customWidth="5"
                :disabled="disabled"
                maxlength="5"
                :dense="dense"
                :filled="filled"
                :inputAction="[`${id}/updatePhoneExtension`, inputAction]"
                :changeAction="changeAction"
                :hasInlineMessages="false"
                class="lni-u-mt0 lni-u-ml1"
                :labelBefore="labelBefore">
            </lni-input-text>
        </div>
    </component>
</template>

<script>
import constrain from '@gov.wa.lni/framework.one-lni.directives/source/constrain.js';
import mask from '@gov.wa.lni/framework.one-lni.directives/source/mask.js';
import text from '@gov.wa.lni/framework.one-lni.core/source/lib/getText.js';

export default {
    name: 'lni-input-phone',
    directives: {
        mask,
        constrain,
    },
    inheritAttrs: false,
    data() {
        return {
            mask: /\\d{3}[\\-]\\d{3}[\\-]\\d{4}/,
            upTo5Digits: /^[\d-]{0,5}$/,
        };
    },
    computed: {
        hasError() {
            return !!this.$store.state[this.id].errorText;
        },
        hasHelperTextSlot() {
            return !!this.$slots.helperText;
        },
        phoneHelperText() {
            return text(this.lang, 'lni-input-phone').phoneHelperText;
        },
    },
    mounted() {
        this.$store.dispatch(`${this.id}/updatePhoneNumber`, {
            sourceId: this.id,
            targetId: `${this.id}_phone`,
        });
        this.$watch('phoneNumber', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                this.$store.commit('setAttribute', {
                    id: this.id + '_phone',
                    attribute: 'value',
                    value: newValue,
                });
            }
        });

        if (this.hasExtension) {
            const errorsCollectedFrom = [
                ...this.errorsCollectedFrom,
            ];
            errorsCollectedFrom.push(`${this.id}_ext`);

            this.$store.commit('setAttribute', {
                id: this.id,
                attribute: 'errorsCollectedFrom',
                value: errorsCollectedFrom,
            });
        }

        this.$store.commit(`${this.id}_phone/customizeValidationMessage`, {
            validationTest: 'patternMismatch',
            global: () => text(this.lang, 'lni-input-phone').messages.patternMismatch.global,
            inline: () => text(this.lang, 'lni-input-phone').messages.patternMismatch.inline,
        });
    },
    methods: {
        onPhoneValueSet() {
            this.$store.dispatch(`${this.id}/updatePhoneNumber`, {
                sourceId: this.id + '_phone',
                targetId: `${this.id}_phone`,
            });
        },
        onPaste() {
            const waitTime = 100;
            const self = this;
            setTimeout(() => {
                // HACK - The masked value isn't being recorded in the store module
                //      so I'm manually forcing it.
                let phoneValue = document.getElementById(`${self.id}_phone`).value;
                self.$store.commit('setAttribute', {
                    id: self.id + '_phone',
                    attribute: 'value',
                    value: phoneValue,
                });
            }, waitTime);
        },
    },
}; </script>