<template>
    <div
        :id="id"
        class="lni-u-flex lni-u-flex-between lni-u-flex-wrap">
        <div>
            <label class="lni-c-file-input">
                <input
                    :id="`${id}_file-input`"
                    ref="input"
                    type="file"
                    class="lni-u-mt3 lni-c-file-input__button"
                    :name="name || id"
                    :files="files"
                    :fileTypes="fileTypes"
                    :multiple="multiple"
                    :required="required"
                    :blur-validation-action="blurValidationAction"
                    :input-validation-action="inputValidationAction"
                    :change-validation-action="changeValidationAction"
                    :disabled="!multiple && files.length >= 1"
                    @change="onChange"
                    @blur="onBlur"
                    @keydown.enter="onEnter"
                    @input="onInput">
                <span class="lni-c-file-input__control">{{ text.label }}</span>
            </label>
            <div
                :id="`${id}_helper-text`"
                class="lni-c-text-field__helper-text lni-u-type--xxs lni-u-line-height--tight">
                {{ text.maxFileSize }} {{ fileSizeConversion(sizeLimit) }} <br>
                <span
                    v-if="showFileNumberLimitText">
                    {{ fileNumberLimit }}
                </span>
                <span
                    v-if="showValidFileTypes">
                    {{ text.validFileTypes }} {{ validFileTypesString }}
                </span>
            </div>
        </div>
        <ul
            v-if="showFileList"
            class="lni-u-list-reset lni-u-mt2 lni-u-pl3">
            <li
                v-for="file in files"
                :key="file.name">
                {{ file.name }} - ({{ fileSizeConversion(file.size) }})
                <button
                    class="lni-u-ml2 icon lnicon-remove"
                    type="button"
                    title="Delete"
                    @click="deleteFile(file.name)">
                    x<span class="lni-u-visually-hidden">{{ text.delete }}</span>
                </button>
            </li>
        </ul>
        <div
            v-if="hasInlineMessages || hasHelperText"
            :id="`${id}_helper-text`"
            :class="hasErrorText ? 'lni-c-text-field__error-message' : null"
            class="lni-c-text-field__helper-text lni-u-type--xxs lni-u-line-height--tight">
            <div v-show="hasErrorText">
                <p>{{ errorText }}</p>
            </div>
            <template v-if="!hasErrorText">
                <slot name="helperText"></slot>
            </template>
        </div>
    </div>
</template>

<script>
import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';

export default {
    name: 'lni-file-upload',

    computed: {
        hasErrorText() {
            return !!this.$store.state[this.id].errorText;
        },
        hasHelperText() {
            return !!this.$slots.helperText;
        },
        validFileTypesString() {
            if (this.fileTypes.length === 0) {
                return '';
            } else if (this.fileTypes.length === 1) {
                return this.fileTypes[0];
            }

            let extensionList = this.fileTypes.join(', ');
            let properExtensionList = extensionList.substr(0, extensionList
                .lastIndexOf(' ')) + ' or ' + extensionList
                .substr(extensionList.lastIndexOf(' ') + 1);
            return properExtensionList;
        },
        fileNumberLimit() {
            return interpolate(this.text.uploadLimit, {
                fileLimit: this.fileLimit,
            });
        },
    },
    mounted() {
        this.updateValidity();
    },
    updated() {
        this.updateValidity();
    },
    methods: {
        updateValidity() {
            // IE is calling "Updated" one final time when chrome isn't.  At this point, the
            // file input has already been cleared of the file so we need to check if it has been added
            // to the store and if so change the validity manually.

            let validity;
            if (this.files.length) {
                validity = {
                    ...this.$refs.input.validity,
                };
                validity.valueMissing = false;
            } else {
                validity = this.$refs.input.validity;
            }
            return this.$store.dispatch('updateValidity', {
                targetId: this.id,
                validity,
            });
        },
        onInput() {
            this.$store.commit(`${this.id}/setDirty`, {
                value: true,
            });

            this.updateValidity().then(() => {
                if (this.hasErrorText && this.inputValidationAction) {
                    this.dispatchEvent('inputValidationAction');
                }

                this.dispatchEvent('inputAction');
            });
        },
        onChange(event) {
            let promise;
            if (this.$store.state[this.id].flags.dirty && this.changeValidationAction) {
                promise = this.dispatchEvent('changeValidationAction', {
                    file: event.target.files,
                });
            } else {
                promise = Promise.resolve();
            }

            promise.then(() => {
                this.dispatchEvent('changeAction');
                this.$store.dispatch(`${this.id}/validateFileTests`, {
                    file: event.target.files,
                    targetId: this.id,
                }).then(() => {
                    this.$store.dispatch(`${this.id}/loadFiles`, {
                        file: event.target.files,
                        targetId: this.id,
                    }).then(() => {
                        this.$emit('change');
                    });
                });
            });
        },
        onEnter() {
            this.dispatchEvent('enterAction');
        },
        onBlur(e) {
            const input = this.$refs.input.parentElement;
            const leavingParent = !input.contains(e.relatedTarget);
            if (leavingParent) {
                this.textFocus = false;
            }
            if (this.$store.state[this.id].flags.dirty && this.blurValidationAction) {
                this.dispatchEvent('blurValidationAction');
            }

            this.dispatchEvent('blurAction');
        },
        deleteFile(fileName) {
            this.$store.dispatch(`${this.id}/deleteFile`, {
                fileName,
            });
            this.$store.dispatch(`${this.id}/validateFileTests`, {
                file: [],
                targetId: this.id,
            }).then(() => {
                this.dispatchEvent('deleteAction');
            });
        },
    },
}; </script>