/* eslint-disable max-len */
// Note: This has been ignored to alphabetize

// lni-collapse, lni-modal, lni-html-element
// have been removed because they are built as part of the main oneLni.js script.
// This is done to make EWN work.

import LniAutoSuggestion from '@gov.wa.lni/component.lni-auto-suggestion';
import LniButton from '@gov.wa.lni/component.lni-button';
import LniCallout from '@gov.wa.lni/component.lni-callout';
import LniCheckbox from '@gov.wa.lni/component.lni-checkbox';
import LniCheckboxList from '@gov.wa.lni/component.lni-checkbox-list';
import LniCollapse from '@gov.wa.lni/component.lni-collapse';
import LniDisclosure from '@gov.wa.lni/component.lni-disclosure';
import LniDisplay from '@gov.wa.lni/component.lni-input-display';
import LniDynamicTemplate from '@gov.wa.lni/component.lni-dynamic-template';
import LniElementContainer from '@gov.wa.lni/component.lni-element-container';
import LniEnglishOnlyLink from '@gov.wa.lni/component.lni-english-only-link';
import LniErrorContainer from '@gov.wa.lni/component.lni-error-container';
import LniFetch from '@gov.wa.lni/component.lni-fetch';
import LniFieldset from '@gov.wa.lni/component.lni-fieldset';
import LniFileUpload from '@gov.wa.lni/component.lni-file-upload';
import LniFileUploads from '@gov.wa.lni/component.lni-file-uploads';
import LniFileUploadForm from '@gov.wa.lni/component.lni-file-upload-form';
import LniInformationalMessage from '@gov.wa.lni/component.lni-informational-message';
import LniInformationalMessages from '@gov.wa.lni/component.lni-informational-messages';
import LniInlineTabs from '@gov.wa.lni/component.lni-inline-tabs';
import LniInputAddress from '@gov.wa.lni/component.lni-input-address';
import LniInputDate from '@gov.wa.lni/component.lni-input-date';
import LniInputEmail from '@gov.wa.lni/component.lni-input-email';
import LniInputHidden from '@gov.wa.lni/component.lni-input-hidden';
import LniInputPassword from '@gov.wa.lni/component.lni-input-password';
import LniInputPhone from '@gov.wa.lni/component.lni-input-phone';
import LniInputSearch from '@gov.wa.lni/component.lni-input-search';
import LniInputText from '@gov.wa.lni/component.lni-input-text';
import LniInputTextArea from '@gov.wa.lni/component.lni-input-textarea';
import LniPager from '@gov.wa.lni/component.lni-pager';
import LniRadios from '@gov.wa.lni/component.lni-radios';
import LniRecaptcha from '@gov.wa.lni/component.lni-recaptcha';
import LniSelect from '@gov.wa.lni/component.lni-select';
import LniSpinner from '@gov.wa.lni/component.lni-spinner';
import LniSyncUrlParams from '@gov.wa.lni/component.lni-sync-url-params';
import LniStatusBadge from '@gov.wa.lni/component.lni-status-badge';
import LniTable from '@gov.wa.lni/component.lni-table';
import LniTabPanel from '@gov.wa.lni/component.lni-tab-panel';
import LniToggleButton from '@gov.wa.lni/component.lni-toggle-button';
import LniToggleTip from '@gov.wa.lni/component.lni-toggle-tip';
import LniMultiselect from '@gov.wa.lni/component.lni-multiselect';
import LniFixedActionButton from '@gov.wa.lni/component.lni-fixed-action-button';
import LniSVGIcon from '@gov.wa.lni/component.lni-svg-icon';
import LniIntersectionObserver from '@gov.wa.lni/component.lni-intersection-observer';
import LniViewToggle from '@gov.wa.lni/component.lni-view-toggle';
import LniNotification from '@gov.wa.lni/component.lni-notification';

export default [
    LniAutoSuggestion,
    LniButton,
    LniCallout,
    LniCheckbox,
    LniCheckboxList,
    LniCollapse,
    LniDisclosure,
    LniDisplay,
    LniDynamicTemplate,
    LniElementContainer,
    LniEnglishOnlyLink,
    LniErrorContainer,
    LniFetch,
    LniFieldset,
    LniFileUpload,
    LniFileUploads,
    LniFileUploadForm,
    LniInformationalMessage,
    LniInformationalMessages,
    LniInlineTabs,
    LniInputAddress,
    LniInputDate,
    LniInputEmail,
    LniInputPassword,
    LniInputPhone,
    LniInputSearch,
    LniInputText,
    LniInputHidden,
    LniInputTextArea,
    LniPager,
    LniRadios,
    LniRecaptcha,
    LniSelect,
    LniSpinner,
    LniSyncUrlParams,
    LniStatusBadge,
    LniTable,
    LniTabPanel,
    LniToggleButton,
    LniToggleTip,
    LniMultiselect,
    LniFixedActionButton,
    LniSVGIcon,
    LniIntersectionObserver,
    LniViewToggle,
    LniNotification,
];