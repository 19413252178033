<template>
    <div
        v-show="hasItems"
        class="lni-c-informational-messages">
        <lni-informational-message
            v-for="(item, index) in items"
            :id="`information-message-${index}`"
            :key="`information-message-${index}`"
            v-bind="item">
            <slot></slot>
        </lni-informational-message>
    </div>
</template>
<script>
/**
*  The lni-informational-messages component.
*  @module components/lni-informational-messages
*/

/**
* The lni-informational-messages
*/

export default {
    name: 'lni-informational-messages',

    computed: {
        hasItems() {
            return this.items.length > 0;
        },
    },
    mounted() {
        this.$watch('items', function updateTableState() {
            this.$store.dispatch(`${this.id}/sync`);
        });
    },
}; </script>