<template>
    <div
        :class="[
            classString,
            'lni-c-status-badge',
            `--${size}`,
            `--${colorClass}`,
            filled ? '--filled' : null,
            'lni-u-type--bold',
            'lni-u-ba'
        ]">
        <div
            v-if="hasSvgIcon"
            class="lni-u-inline-flex ini-u-items-center">
            <slot
                name="svgIcon"
                class="lni-c-status-badge__icon"></slot>
        </div>
        <span
            v-else-if="icon.length > 0"
            aria-hidden="true"
            :class="`lni-c-status-badge__icon lnicon--${icon}`">
        </span>
        <div
            v-else-if="statusType.length > 0 && statusType !== 'info'"
            class="lni-u-inline-flex lni-u-items-center">
            <lni-svg-icon
                v-if="statusType === 'ok'"
                :id="`${id}_svg-icon`"
                class="lni-c-status-badge__icon"
                viewBox="0 0 10 10"
                fill="japanese-laurel">
                <ok></ok>
            </lni-svg-icon>
            <lni-svg-icon
                v-else-if="statusType === 'error'"
                :id="`${id}_svg-icon`"
                class="lni-c-status-badge__icon"
                viewBox="0 0 16 16"
                fill="monza">
                <error></error>
            </lni-svg-icon>
            <lni-svg-icon
                v-else-if="statusType === 'warning'"
                :id="`${id}_svg-icon`"
                class="lni-c-status-badge__icon"
                viewBox="0 0 16 16"
                fill="gamboge">
                <warning></warning>
            </lni-svg-icon>
        </div>
        <span
            v-else
            aria-hidden="true"
            :class="`lni-c-status-badge__icon lnicon--info`">
        </span>
        <span class="lni-c-status-badge__text">{{ text }}</span>
    </div>
</template>

<script>
import icons from '@gov.wa.lni/component.lni-status-badge/icons';

export default {
    name: 'lni-status-badge',
    components: icons,
    computed: {
        hasSvgIcon() {
            return !!this.$slots.svgIcon;
        },
        colorClass() {
            //This will provide the suffix for the class which determines the color palette
            switch (this.statusType) {
                case 'warning': {
                    return 'yellow';
                }
                case 'error': {
                    return 'red';
                }
                case 'info': {
                    return 'blue';
                }
                case 'ok': {
                    return 'green';
                }
                default: return '';
            }
        },
    },
}; </script>