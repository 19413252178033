import text from '@gov.wa.lni/framework.one-lni/source/locale/en.js';

export default () => ({
    state: {
        text: text['lni-file-upload-form'],
        files: [], //  An array representing the selected files.
        uploadedFiles: [], //An array representing the successfully uploaded files
        fileTypes: [], // The list of files only that are allowed
        sizeLimit: 7340032,  //7MB by default,
        fileLimit: 1,
        charLimit: 1000,
        hasVideos: false, // type of attachment. file size varies based on videos or documents
        multiple: true,
        errorText: '',
        startTimer: false,
        uploadAction: '',
        errors: [],
        validity: null,
        flags: {
            dirty: false,
            touched: false,
        },
        inputValidationAction: 'validate',
        changeValidationAction: 'validate',
        blurValidationAction: 'validate',
    },
});