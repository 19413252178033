export default (context, payload) => {

    const moduleState = context.rootState[payload.targetId];
    const pattern = moduleState.pattern;
    const moduleId = context.getters.moduleId;
    const month = context.rootState[`${moduleId}_month`].value;
    const day = context.rootState[`${moduleId}_day`].value;

    // Note that this is the year state object, not the value like the others.
    const year = context.rootState[`${moduleId}_year`];
    const leapDay = 29;

    let validity = {};
    if (moduleState.validity) {
        validity = {
            ...moduleState.validity,
        };
    }

    // If the date input is not required and there is no value in any of the inputs
    // do not make any of them invalid.
    if (!moduleState.required
        && (month.length === 0 && day.length === 0 && year.value.length === 0)) {
        validity.invalidMonth = false;
        return validity;
    }

    //Test for invalid Month (pattern)
    validity.invalidMonth = !pattern.test(moduleState.value);

    let yearValidity = {};
    if (year.validity) {
        yearValidity = {
            ...year.validity,
        };
    }

    // Test valid Leap Year
    if ((/^2|02/).test(month) && day === leapDay.toString() && year.value) {
        yearValidity.leapYear = !(/(((19|20)(04|08|[2468][048]|[13579][26]))|2000)/)
            .test(year.value);
        context.commit('setAttribute', {
            id: `${moduleId}_year`,
            attribute: 'validity',
            value: yearValidity,
        }, {
            root: true,
        });

        context.dispatch('updateValidity', {
            targetId: `${moduleId}_year`,
            yearValidity,
        }, {
            root: true,
        }).then(() => {
            // Now we call the generic validate function on this component which will kick off
            // the regular pipeline to validate itself and those above it.
            context.dispatch('validate', {
                targetId: `${moduleId}_year`,
                validation: {
                    errorsCollectedBy: moduleState.errorsCollectedBy,
                },
            }, {
                root: true,
            });
        });
    } else if (year.value) {
        yearValidity.leapYear = false;
        context.commit('setAttribute', {
            id: `${moduleId}_year`,
            attribute: 'validity',
            value: yearValidity,
        }, {
            root: true,
        });

        context.dispatch('updateValidity', {
            targetId: `${moduleId}_year`,
            yearValidity,
        }, {
            root: true,
        }).then(() => {
            // Now we call the generic validate function on this component which will kick off
            // the regular pipeline to validate itself and those above it.
            context.dispatch('validate', {
                targetId: `${moduleId}_year`,
                validation: {
                    errorsCollectedBy: moduleState.errorsCollectedBy,
                },
            }, {
                root: true,
            });
        });
    }

    return validity;
};