import actions from '@gov.wa.lni/component.lni-radios/actions';
import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';
import text from '@gov.wa.lni/framework.one-lni.core/source/lib/getText.js';

export default () => ({
    state: {
        lang: '',
        required: false,
        labelText: '',
        legend: '',
        options: null,
        value: '',
        isColumn: true,
        dense: true,
        hasInlineMessages: true,
        changeAction: '',
        messages: {
            valueMissing: {
                global: state => interpolate(
                    text(state.lang, 'lni-radios').messages.valueMissing.global, {
                        legend: state.legend ? state.legend : '',
                        labelText: state.labelText,
                    },
                ),
                inline: state => text(state.lang, 'lni-radios').messages.valueMissing.inline,
            },
        },
        errorText: '', // triggers error state in DOM
        errors: [],
        validity: null,
        flags: {
            dirty: false,
            touched: false,
        },
        changeValidationAction: 'validate',
        customValidityAction: '',
    },
    actions: {
        ...actions,
    },
});