<template>
    <div
        :id="id + '_display'"
        ref="display"
        class="lni-u-relative lni-c-input-display"
        :class="dense ? '--dense' : ''">
        <label
            :for="id + '_input'"
            class="lni-c-input-display__label">{{ labelText }}</label>
        <input
            :id="id + '_input'"
            class="lni-c-input-display__input"
            type="text"
            :value="value"
            disabled>
    </div>
</template>
<script>
export default {
    name: 'lni-input-display',

}; </script>