<template>
    <div
        :class="{
            'lni-c-spinner__overlay': !inline,
            '--inline': inline,
        }">
        <div class="lni-c-spinner__wrapper">
            <div class="lni-c-spinner">
                <div class="lni-c-spinner__dot"></div>
                <div class="lni-c-spinner__dot"></div>
                <div class="lni-c-spinner__dot"></div>
                <div class="lni-c-spinner__dot"></div>
                <div class="lni-c-spinner__dot"></div>
                <div class="lni-c-spinner__dot"></div>
                <div class="lni-c-spinner__dot"></div>
                <div class="lni-c-spinner__dot"></div>
            </div>
            <span class="lni-c-spinner__text">{{ label }}</span>
        </div>
    </div>
</template>


<script>/**
 *  The lni-spinner component.
 *  @module components/lni-spinner
 */

/**
 * The lni-spinner
 */
export default {
    name: 'lni-spinner',

    computed: {
        label() {
            return this.$store.getters[`${this.id}/getSpinnerlabel`];
        },
    },

}; </script>