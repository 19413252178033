export default context => {
    const moduleId = context.getters.moduleId;
    const errors = context.state.errors;
    if (Array.isArray(errors) && errors.length > 0) {
        if (context.state.scrollToErrorsList) {
            setTimeout(() => context.dispatch('scrollToErrors', true), 1);
        }
    }

    context.commit('setAttribute', {
        id: moduleId,
        attribute: 'visible',
        value: true,
    }, {
        root: true,
    });

};