const columnHeaderDefaults = () => ({
    // The Id for the header field
    source: '',

    // The Text displayed in the header
    displayText: '',

    // Whether the column is sortable
    sortable: false,

    // classes to apply to this header
    headerClasses: '',
});

const enterKeyCode = 13;
const spaceBarKeyCode = 32;
export default {
    name: 'table-header',
    props: [
        'columns',
        'sort',
        'tableId',
    ],
    render(createElement) {
        return this.generateHeader(createElement);
    },
    methods: {
        generateHeader(createElement) {
            const self = this;
            const sortObject = this.sort;
            const children = this.columns.map(c => {
                const columnHeaderOptions = columnHeaderDefaults();
                const sortDirection = sortObject[columnHeaderOptions.source];
                Object.assign(columnHeaderOptions, c);

                let sortIconName = '';

                let ariaSortText = '';
                if (sortDirection) {
                    sortIconName = sortDirection === 'asc' ? 'caret--up' : 'caret--down';
                    ariaSortText = sortDirection === 'asc' ? 'ascending' : 'descending';
                } else {
                    ariaSortText = 'none';
                }

                if (columnHeaderOptions.sortable) {
                    const options = {
                        on: {
                            keyup(e) {
                                if (e.which === enterKeyCode || e.which === spaceBarKeyCode) {
                                    self.$emit('sort', {
                                        columnSource: columnHeaderOptions.source,
                                    });
                                }
                            },
                            click() {
                                self.$emit('sort', {
                                    columnSource: columnHeaderOptions.source,
                                });
                            },
                        },

                        attrs: {
                            role: 'button',
                            tabIndex: '0',
                            scope: 'col',
                            abbr: columnHeaderOptions.displayText,
                        },
                    };
                    const textSpan = createElement('span', {}, [columnHeaderOptions.displayText]);

                    const icon = createElement('span', {
                        attrs: {
                            id: `${this.tableId}__sortIcon-${columnHeaderOptions.source}`,
                            'aria-hidden': 'true',
                        },
                        class: `lni-u-mr1 lnicon--${sortIconName} lni-c-icon`,
                    });

                    const containingSpan = createElement('span', options, [textSpan, icon]);
                    return createElement('th', {
                        class: [
                            'lni-c-table__th',
                            'lni-u-text--left',
                            '--sortable',
                            // The users's choice for text alignment will override the one above
                            // if the utility class is added to the headerClasses property
                            columnHeaderOptions.headerClasses,
                        ],

                        attrs: {
                            'aria-sort': ariaSortText,
                        },
                    }, [containingSpan]);
                }
                const textSpan = createElement('span', {}, [columnHeaderOptions.displayText]);
                return createElement('th', {
                    class: [
                        'lni-c-table__th',
                        'lni-u-text--left',
                        // The users's choice for text alignment will override the one above
                        // if the utility class is added to the headerClasses property
                        columnHeaderOptions.headerClasses,
                    ],
                }, [textSpan]);

            });

            return createElement('TR', children);
        },
    },
};