// eslint-disable-next-line max-len
import dismissWarnings from '@gov.wa.lni/component.lni-english-only-link/actions/dismissWarnings.js';

export default () => ({
    state: {
        type: 'internal',
        cookieName: 'dismiss-english-only-warning',
        text: {
            title: '',
            directoryLinkText: '',
            directoryLinkUrl: '',
            internalMessage: '',
            externalMessage: '',
            exit: '',
            continueToContent: '',
            dismissWarning: '',
            englishOnly: '',
        },
    },
    actions: {
        dismissWarnings,
    },
});