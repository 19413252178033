import {
    isArray,
} from 'util';

export const sync = function sync(context) {

    const items = context.state.items;
    if (!items || !isArray(items)) {
        return;
    }

    // if the item has a link property, then an lni-anchor component will be rendered.
    // This child one-lni component(lni-anchor) needs its attributes set on its module so that it
    // will be rerendered when the parent lni-informational-messages component
    // is re-rendered.
    items.forEach((item, index) => {
        if (item.link) {
            context.commit('setAttribute', {
                id: `information-message-${index}`,
                attribute: 'message',
                value: item.message,
            }, {
                root: true,
            });

            context.commit('setAttribute', {
                id: `information-message-${index}`,
                attribute: 'link',
                value: item.link,
            }, {
                root: true,
            });
        }
    });
};

export default {
    sync,
};