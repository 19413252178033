import text from '@gov.wa.lni/framework.one-lni.core/source/lib/getText.js';

export default () => ({
    state: {
        inline: false,
        lang: '',

    },
    getters: {
        getSpinnerlabel(state) {
            return text(state.lang, 'lni-spinner').label;
        },
    },
});