const LANGUAGE_VARIABLES_ENDPOINT = '/language-variables/';
// Prevent multiple API calls from multiple instances using this
let languageVariablesPromise = null;

const getText = function(filter, oneLni) {
    let endpoint = `${oneLni.environment.current.apiLocation2}${LANGUAGE_VARIABLES_ENDPOINT}?filter=${filter}`;
    if (!languageVariablesPromise) {
        languageVariablesPromise = fetch(endpoint, {
            mode: 'cors',
        }).then(response => response.json())
            .then(data => data.results);
    }
    return languageVariablesPromise;
};

export {
    getText,
};