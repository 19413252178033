const loadFile = function(file) {
    return new Promise(resolve => {
        const name = file.name;
        let reader = new FileReader();
        reader.onload = () => {
            let arrayBuffer = reader.result;
            let byteArray = new Uint8Array(arrayBuffer);
            let arr = [];
            for (let i = 0; i < byteArray.byteLength; i++) {
                arr.push(byteArray[i]);
            }
            let newArrayItem = {
                name,
                data: arr,
            };
            resolve(newArrayItem);
        };
        reader.readAsArrayBuffer(file);
    });
};


export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];

    let fileList = payload.file;
    let newFiles = [];
    if (moduleState && moduleState.files.length) {
        newFiles = [
            ...moduleState.files,
        ];
    }

    for (let i = 0; i < fileList.length; i++) {

        context.commit('setAttribute', {
            id: moduleId,
            attribute: 'errorText',
            value: '',
        }, {
            root: true,
        });
        if (!moduleState.multiple) {
            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'files',
                value: [],
            }, {
                root: true,
            });
        }

        newFiles.push(fileList[i]);
        context.commit('setAttribute', {
            id: moduleId,
            attribute: 'files',
            value: newFiles,
        }, {
            root: true,
        });
    }

    let promises = [];
    newFiles.forEach(f => {
        promises.push(loadFile(f));
    });

    Promise.all(promises)
        .then(results => {
            newFiles.forEach(f => {
                let fileData = results.filter(d => d.name === f.name)[0];
                f.data = fileData.data;
            });

            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'files',
                value: newFiles,
            }, {
                root: true,
            });

            document.getElementById(`${moduleId}_file-input`).value = '';
        });
};