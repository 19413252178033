<script>
import Vue from 'vue';

export default {
    name: 'LniDynamicTemplate',
    created() {
        // using $attrs because templateString is needed
        // before the store's computed getter is ready
        this.$options.template = this.$attrs.templateString;

        this.$watch('$attrs.templateString', newVal => {
            this.templateString = newVal;
            this.$forceUpdate();
        });
    },
    render(createElement) {
        return createElement(Vue.compile(this.templateString));
    },
};
</script>