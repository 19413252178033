export default {
    name: 'table-caption',
    props: ['id', 'displayedCaption'],
    render(createElement) {
        if (!this.displayedCaption) {
            return null;
        }

        return createElement('caption', {
            attrs: {
                id: this.id,
            },
        },
        [this.displayedCaption]);
    },
};