import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';
import text from '@gov.wa.lni/framework.one-lni.core/source/lib/getText.js';

export default () => ({
    state: {
        lang: null,
        name: '',
        changeAction: '',
        pasteAction: '',
        inputAction: '',
        blurAction: '',
        enterAction: '',
        classString: '',
        customWidth: false,
        dense: false,
        disabled: false,
        filled: true,
        fullWidth: false,
        formElement: 'input',
        legend: '',
        labelText: '',
        leadingIcon: '',
        minlength: null,
        maxlength: null,
        min: null,
        max: null,
        pattern: null,
        required: false,
        trailingIcon: '',
        trailingIconIsClickable: false,
        type: 'text',
        ref: 'input',
        value: '',
        hasInlineMessages: true, // removes the space reserved for error messages
        persistHelperText: false,
        labelBefore: false,
        ariaLabelledby: null,
        ariaDescribedby: null,
        ariaAutocomplete: null,
        ariaControls: null,
        ariaActivedescendant: null,
        autocomplete: null,
        messages: {
            valueMissing: {
                global: state => interpolate(
                    text(state.lang, 'lni-input-text').messages.valueMissing.global, {
                        labelText: state.labelText,
                        legend: state.legend ? `${state.legend} ` : '',
                    },
                ),
                inline: state =>
                    text(state.lang, 'lni-input-text').messages.valueMissing.inline,
            },
            /* value does not match pattern attribute/regex */
            patternMismatch: {
                global: state => interpolate(
                    text(state.lang, 'lni-input-text').messages.patternMismatch.global,
                    {
                        labelText: state.labelText,
                        legend: state.legend ? `${state.legend} ` : '',
                    },
                ),
                inline: state => {
                    const example = state.example ? '. Example: ' + state.example : '';
                    return interpolate(
                        text(state.lang, 'lni-input-text').messages.patternMismatch.inline, {
                            example,
                        },
                    );
                },
            },
            /* value does not match input type */
            typeMismatch: {
                global: state => interpolate(
                    text(state.lang, 'lni-input-text').messages.typeMismatch.global, {
                        labelText: state.labelText,
                        legend: state.legend ? `${state.legend} ` : '',
                    },
                ),
                inline: state => {
                    const example = state.example ? '. Example: ' + state.example : '';
                    return interpolate(
                        text(state.lang, 'lni-input-text').messages.typeMismatch.inline, {
                            example,
                        },
                    );
                },
            },
            tooLong: {
                global: state => interpolate(
                    text(state.lang, 'lni-input-text').messages.tooLong.global, {
                        labelText: state.labelText,
                        limit: state.limit ? state.limit : '',
                    },
                ),
                inline: state => interpolate(
                    text(state.lang, 'lni-input-text').messages.tooLong.inline, {
                        limit: state.limit ? state.limit : '',
                    },
                ),
            },
            tooShort: {
                global: state => interpolate(
                    text(state.lang, 'lni-input-text').messages.tooShort.global, {
                        labelText: state.labelText || '',
                        num: state.minlength,
                        difference: state.minlength,
                        s: state.minLength > 1 ? 's' : ' ',
                    },

                ),
                inline: state => interpolate(
                    text(state.lang, 'lni-input-text').messages.tooShort.inline, {
                        labelText: state.labelText,
                        num: state.minlength,
                        difference: state.minlength - state.value.length,
                        s: state.minlength - state.value.length > 1 ? 's' : '',
                    },
                ),

            },
        },
        errorText: '',
        errors: [],
        validity: null,
        flags: {
            dirty: false,
            touched: false,
        },
        inputValidationAction: 'validate',
        changeValidationAction: 'validate',
        blurValidationAction: 'validate',
        customValidityAction: '',
        customValidationAction: '',
    },
});