/* eslint-disable max-len */
import clear from '@gov.wa.lni/component.lni-auto-suggestion/actions/clear.js';
import syncFromInnerComponent from '@gov.wa.lni/component.lni-auto-suggestion/actions/syncFromInnerComponent.js';
import syncToInnerComponent from '@gov.wa.lni/component.lni-auto-suggestion/actions/syncToInnerComponent.js';
import syncFromInnerComponentSearch from '@gov.wa.lni/component.lni-auto-suggestion/actions/syncFromInnerComponentSearch.js';
import syncToInnerComponentSearch from '@gov.wa.lni/component.lni-auto-suggestion/actions/syncToInnerComponentSearch.js';

export default {
    clear,
    syncFromInnerComponent,
    syncToInnerComponent,
    syncFromInnerComponentSearch,
    syncToInnerComponentSearch,
};