import sendRequest from '@gov.wa.lni/component.lni-fetch/actions/sendRequest.js';
import setError from '@gov.wa.lni/component.lni-fetch/actions/setError.js';
import setResponseData from '@gov.wa.lni/component.lni-fetch/actions/setResponseData.js';

export default () => ({
    state: {
        relativeEndpoint: '', //relative to environment root
        fullEndpoint: '', //use to override the environment endpoint provided by this.$oneLni
        fetchOptions: {
            mode: 'cors',
        }, // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Supplying_request_options
        params: {},
        currentState: 'idle', // 'idle', 'pending', 'success', 'error'
        responseData: null,
        error: '',
        minLoadingTime: 400, // show loader so users register a change
        animateFetch: true, //default fades the slot contents
        showOverlaySpinner: false, //default uses an inline spinner
        successAction: '',
        errorAction: '',
    },
    actions: {
        sendRequest,
        setError,
        setResponseData,
    },
    getters: {
        queryString: state =>  {
            const string = Object.keys(state.params)
            //remove empty keys
                .filter(key => state.params[key])
            //encode
                .map(key => `${key}=${encodeURIComponent(state.params[key])}`)
                .join('&');
            return string ? '?' + string : '';
        },
        requestUrl: (state, getters) => oneLni => {
            const apiLocation = oneLni.environment.current.apiLocation;
            const queryString = getters.queryString;
            const endpoint = state.fullEndpoint ? state.fullEndpoint : `${apiLocation}${state.relativeEndpoint}`;
            return `${endpoint}/${queryString}`;
        },
    },
    mutations: {
        CHANGE_STATE(state) {
            let nextState;
            switch (state.currentState) {
                case 'idle': nextState = 'pending';
                    break;
                case 'pending': nextState = state.error ? 'error' : 'success';
                    break;
                case 'error': nextState = 'pending';
                    break;
                case 'success': nextState = 'pending';
                    break;
                default: nextState = 'idle';
            }
            state.currentState = nextState;
        },
        SET_PARAMS(state, payload) {
            state.params = {
                ...state.params,
                ...payload,
            };
        },
        SET_ERROR(state, payload) {
            state.error = payload;
        },
        SET_RESPONSE_DATA(state, payload) {
            state.responseData = payload;
        },
    },
});