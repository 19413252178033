<template>
    <div
        class="lni-c-element-container"
        :class="[{'--placeholder': state}]">
        <transition name="lni-o-fade">
            <lni-spinner
                v-if="uiState === 'loading'"
                :id="id + '_spinner'"
                class="--small --overlay"></lni-spinner>
        </transition>

        <!-- If state attribute and not '' -->
        <div
            v-if="state && uiState!=''"
            v-slide="uiState !== 'loading'">
            <span
                v-if="uiState === 'error' || uiState === 'info' || uiState === 'warn'"
                class="lni-c-alert lni-u-mv4 lni-u-mt2"
                role="alert"
                :class="{
                    '--warning': uiState === 'warn',
                    '--error': uiState === 'error',
                    '--info': uiState === 'info'}">
                <span
                    :class="'lnicon--' + iconClass"
                    aria-hidden="true"></span>{{ message }}</span>
            <slot
                v-if="true || uiState === 'success'"></slot>
        </div>
        <template v-else-if="!state && uiState === 'no-state'">
            <slot></slot>
        </template>
    </div>
</template>

<script>
import slide from '@gov.wa.lni/framework.one-lni.directives/source/slide.js';

/**
 *  The lni-element-container component.
 *  @module components/lni-element-container
 */

/**
 * The lni-element-container is a basic element-container.
 */
/** loading, success, error,  */

export default {
    name: 'lni-element-container',
    directives: {
        slide,
    },

    // TODO: setup validation on vuex states
    // validator(value) {
    //     const states = [
    //         'loading',
    //         'success',
    //         'error',
    //         'info',
    //         'warn',
    //         '',
    //     ];
    //     return states.indexOf(value) !== -1;
    // },
    computed: {
        uiState() {
            return this.state || this.state === '' ? this.state : 'no-state';
        },
        iconClass() {
            const iconClasses = {
                error: 'exclamation',
                warn: 'warning',
                info: 'info',
            };
            return iconClasses[this.uiState];
        },
    },
}; </script>