import {
    updatePhoneExtension,
    updatePhoneNumber,
} from '@gov.wa.lni/component.lni-input-phone/actions/sync.js';

import text from '@gov.wa.lni/framework.one-lni/source/locale/en.js';

export default () => ({
    state: {
        lang: '',
        classString: '',
        isFieldset: true,
        dense: false,
        disabled: false,
        filled: true,
        hasExtension: true,
        labelText: '',
        phoneFieldsetLabelText: text['lni-input-phone'].phoneFieldsetLabelText,
        labelBefore: false,
        extensionLabelText: text['lni-input-phone'].extensionLabelText,
        phoneExtension: '',
        phoneNumber: '',
        required: false,
        changeAction: '',
        hasInlineMessages: true,
        rtl: false,
        errorText: '',
        errors: [],
        validity: null,
        flags: {
            dirty: false,
            touched: false,
        },
        inputValidationAction: 'validate',
        changeValidationAction: 'validate',
        blurValidationAction: 'validate',
        inputAction: '',
        errorsCollectedFrom: [
            id => `${id}_phone`,
        ],
    },
    actions: {
        updatePhoneNumber,
        updatePhoneExtension,
    },
});