export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const allFiles = [
        ...context.state.files,
    ];

    for (let i = 0; i < allFiles.length; i++) {
        let file = allFiles[i];
        if (file.name === payload.fileName) {
            allFiles.splice(i, 1);
        }
    }

    context.commit('setAttribute', {
        id: moduleId,
        attribute: 'files',
        value: allFiles,
    }, {
        root: true,
    });
};