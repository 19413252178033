import {
    updateDay,
    updateMonth,
    updateYear,
} from '@gov.wa.lni/component.lni-input-date/actions/updateValues.js';

import actions from '@gov.wa.lni/component.lni-input-date/actions';
import text from '@gov.wa.lni/framework.one-lni/source/locale/en.js';
import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';

export default () => ({
    state: {
        hasInlineMessages: true,
        showDatePicker: true,
        customWidth: false,
        classString: '',
        disabled: false,
        labelText: '',
        labelledby: '',
        helperText: text['lni-input-date'].helperText,
        maxDate: '1/1/2030',
        minDate: '1/1/1900',
        required: false,
        dayValue: '',
        monthValue: '',
        yearValue: '',
        value: '',
        filled: true,
        dense: true,
        clickAction: '',
        blurAction: '',
        changeAction: '',
        inputAction: '',
        pasteAction: '',
        labelBefore: false,

        // validation
        errorText: '',
        errors: [],
        validity: {
            inputMissing: false,
            dateIsInvalid: false,
        },
        flags: {
            dirty: false,
            touched: false,
        },
        changeValidationAction: 'customValidate',
        blurValidationAction: 'customValidate',
        customValidationAction: 'customValidate',
        customValidityAction: '',
        messages: {
            dateIsInvalid: {
                global: state => interpolate(text['lni-input-date'].messages.dateIsInvalid.global, {
                    labelText: state.labelText,
                }),
                inline: () => text['lni-input-date'].messages.dateIsInvalid.inline,
            },
            inputMissing: {
                global: state =>  interpolate(text['lni-input-date'].messages.inputMissing.global, {
                    labelText: state.labelText,
                }),
                inline: () => text['lni-input-date'].messages.inputMissing.inline,
            },
        },
        errorsCollectedFrom: [
            id => `${id}_day`,
            id => `${id}_month`,
            id => `${id}_year`,
        ],
    },
    actions: {
        updateDay,
        updateMonth,
        updateYear,
        ...actions,
    },
    getters: {
        dayValue(state, getters, rootState) {
            return rootState[`${getters.moduleId}_day`].value;
        },
        monthValue(state, getters, rootState) {
            return rootState[`${getters.moduleId}_month`].value;
        },
        yearValue(state, getters, rootState) {
            return rootState[`${getters.moduleId}_year`].value;
        },
        value(state, getters) {
            const m = getters.monthValue;
            const d = getters.dayValue;
            const y = getters.yearValue;
            const yearLength = 4;
            if ( d && m && y && y.length === yearLength ) {
                const day = d.length === 1 ? `0${d}` : d;
                const month = m.length === 1 ? `0${m}` : m;
                return `${month}/${day}/${y}`;
            }
            return '';

        },
        earliestYear(state) {
            return state.minDate.split('/')[2];
        },
        latestYear(state) {
            return state.maxDate.split('/')[2];
        },
    },
});