<template>
    <section
        :class="['lni-c-callout__container',
                 'lni-u-flex',
                 'lni-u-flex-column',
                 'lni-u-items-center']">
        <div
            :class="['lni-u-mv1',
                     'lni-c-callout',
                     'lni-u-pv4',
                     'lni-u-ph3',
                     'lni-u-text--center']">
            <h3
                :class="['lni-u-heading--3',
                         {'lni-u-mb3': (actions && actions.length > 0)}]">
                {{ header }}
            </h3>
            <div
                v-for="(action, index) in actions"
                :key="action.label"
                :class="[((actions.length > 1 && index == 0) ||
                    (hasSlot && (index == actions.length - 1)))? 'lni-u-mb3': null]">
                <p
                    v-if="action.description"
                    :class="['lni-u-heading--4',
                             'lni-c-callout__headline']">
                    {{ action.description }}
                </p>
                <a
                    v-if="action.label && action.route"
                    class="lni-c-button lni-u-inline-block lni-u-text--all-caps"
                    :class="action.description ? 'lni-u-mt3' : null"
                    :href="action.route">
                    {{ action.label }}
                </a>
            </div>
            <slot name="body"></slot>
            <div
                v-if="hasSlot"
                class="lni-c-callout__footer">
                <!-- TODO: Should this be a named slot? -->
                <slot></slot>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'lni-callout',
    computed: {
        hasBodySlot() {
            return !!this.$slots.body;
        },
        hasSlot() {
            return !!this.$slots.default;
        },
    },
}; </script>