export default (context, payload) => {
    const moduleState = context.rootState[payload.targetId];
    let validity = {};
    const files = [...payload.file, ...moduleState.files];
    if (moduleState.validity) {
        validity = {
            ...moduleState.validity,
        };
    }

    //Check for selected file
    if (validity.valueMissing === true && files && files.length) {
        validity.valueMissing = false;
    }

    // IE is calling this function a second time with no file
    if (files && files.length) {
        validity.invalidFileSize = false;
        validity.invalidFileType = false;
        for (let i = 0;  i < files.length; i++ ) {
            //Test file size if required
            if (moduleState.sizeLimit && !validity.invalidFileSize) {
                validity.invalidFileSize = files[i].size > moduleState.sizeLimit;
            }

            //test file extension if required
            if (moduleState.fileTypes && !validity.invalidFileType) {
                const fileExtension = files[i].name.split('.').pop();

                let fileTypeCheck = moduleState.fileTypes
                    .filter(f => f.toLowerCase() === fileExtension.toLowerCase()).length === 0;
                validity.invalidFileType = fileTypeCheck;
            }
        }

        if (moduleState.fileLimit) {
            validity.uploadLimit = files.length > moduleState.fileLimit;
        }

    }
    context.dispatch('updateValidity', {
        targetId: payload.targetId,
        validity,
    }, {
        root: true,
    });

    context.dispatch('validate', {
        targetId: payload.targetId,
        validation: {
            errorsCollectedBy: moduleState.errorsCollectedBy,
        },
    }, {
        root: true,
    });
};