export default () => ({
    state: {
        // Defaults to size sm (24x24)
        size: 'sm',
        // Set to override size settings
        width: null,
        height: null,
        // Set to null to avoid color palettes
        fill: 'mid-gray',
        stroke: 'white',
    },
});