import text from '@gov.wa.lni/framework.one-lni/source/locale/en.js';

const countFromEnd = (index, array) => array.length - 1 - index;

// Creates a comma separated list
export default (array, conjunction = text.readableList.conjunction) => array.map((word, index) => {
    if ( countFromEnd(index, array) === 0 ) {
        return word;
    } else if ( countFromEnd(index, array) === 1 ) {
        return word + ` ${conjunction} `;
    }
    return word + ', ';
}).join('');