import actions from '@gov.wa.lni/component.lni-auto-suggestion/actions';
import text from '@gov.wa.lni/framework.one-lni/source/locale/en.js';
import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';

export default () => ({
    state: {
        name: '',
        isSearch: true,
        changeAction: '',
        pasteAction: '',
        enterAction: '',
        inputAction: '',
        blurAction: '',
        classString: '',
        clearAction: '',
        cleared: false,
        customWidth: false,
        placeholder: '',
        labelText: '',
        fullWidth: false,
        value: '',
        options: [],
        numberReturned: 7,
        clientSideFiltering: true,
        ariaDescribedby: null,
        ...text['lni-auto-suggestion'],
        required: false,
        messages: {
            valueMissing: {
                global: state => interpolate(
                    text['lni-auto-suggestion'].messages.valueMissing.global, {
                        labelText: state.labelText,
                        legend: state.legend ? `${state.legend} ` : '',
                    },
                ),
                inline: () => text['lni-auto-suggestion'].messages.valueMissing.inline,
            },
            valueMismatch: {
                global: state => interpolate(
                    text['lni-auto-suggestion'].messages.valueMismatch.global, {
                        labelText: state.labelText,
                        legend: state.legend ? `${state.legend} ` : '',
                    },
                ),
                inline: () => text['lni-auto-suggestion'].messages.valueMismatch.inline,
            },
        },
        hasInlineMessages: false,
        persistHelperText: false,
        errorText: '',
        errors: [],
        errorsCollectedFrom: [
            id => `${id}_text`,
        ],
        validity: null,
        flags: {
            dirty: false,
            touched: false,
        },
        inputValidationAction: 'validate',
        changeValidationAction: 'validate',
        blurValidationAction: 'validate',
        customValidityAction: '',
        customValidationAction: '',
    },
    actions: {
        ...actions,
    },
});