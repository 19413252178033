<template>
    <div
        :class="[
            'lni-c-checkbox',
            'lni-u-inline-block',
            {'--disabled': disabled},
            {'--required': required },
            classString
        ]">
        <input
            :id="id"
            ref="input"
            :required="required"
            :disabled="disabled"
            :class="{'--hasFocus': hasKeyboardFocus}"
            :value="value"
            :checked="checked"
            class="lni-c-checkbox__input"
            type="checkbox"
            @change="onChange"
            @keyup="hasKeyboardFocus = true"
            @click="hasKeyboardFocus = false"
            @blur="hasKeyboardFocus = false">
        <label
            :for="id"
            class="lni-c-checkbox__label lni-u-inline-block">
            <div class="lni-c-checkbox__indicator"></div>
            <span class="lni-c-checkbox__label-text">{{ labelText }}</span>
        </label>
        <p
            v-if="description"
            class="lni-c-checkbox__description">
            {{ description }}
        </p>

        <div
            v-if="hasInlineMessages || hasHelperText"
            :id="`${id}_helper-text`"
            :class="hasErrorText ? 'lni-c-text-field__error-message' : null"
            class="lni-c-text-field__helper-text lni-u-type--xxs lni-u-line-height--tight">
            <template v-show="hasErrorText">
                <p>{{ errorText }}</p>
            </template>
            <template v-show="!hasErrorText">
                <slot name="helperText"></slot>
            </template>
        </div>
    </div>
</template>

<script>
/**
 *  The lni-checkbox component.
 *  @module components/lni-checkbox
 */


/**
 * The lni-checkbox is a basic checkbox.
 */
export default {
    name: 'lni-checkbox',

    data() {
        return {
            hasKeyboardFocus: false,
        };
    },
    computed: {
        hasErrorText() {
            return !!this.$store.state[this.id].errorText;
        },
        hasHelperText() {
            return !!this.$slots.helperText;
        },
    },
    mounted() {
        this.updateValidity();
    },
    methods: {
        onChange(event) {
            this.checked = event.target.checked;
            this.$store.commit(`${this.id}/setDirty`, {
                value: true,
            });
            this.updateValidity().then(() => {
                if (this.$store.state[this.id].flags.dirty && this.changeValidationAction) {
                    this.dispatchEvent('changeValidationAction');
                }

                this.dispatchEvent('changeAction').then(() => {
                    this.$emit('change');
                });
            });
        },
        updateValidity() {
            return this.$store.dispatch('updateValidity', {
                targetId: this.id,
                validity: this.$refs.input.validity,
            });
        },
    },
}; </script>