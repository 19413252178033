<template>
    <div
        class="lni-c-select"
        :class="[{
                     '--required': required,
                     [`--custom-width-${customWidth}`]: customWidth,
                     '--activated': hasSelection,
                     '--dense': dense,
                     '--filled': filled,
                     '--outlined': !filled,
                     '--has-error': hasErrorText,
                     '--label-before': labelBefore,
                 },
                 classString,
        ]">
        <select
            :id="id"
            ref="select"
            :name="name"
            :aria-describedby="describedBy"
            class="lni-c-select__field lni-u-full-width"
            :value="value"
            :required="required"
            :disabled="disabled"
            @change="onChange">
            <option
                v-if="placeholder"
                :key="placeholder"
                value="">
                {{ placeholder }}
            </option>

            <option
                v-for="(option, index) in options"
                :key="`${id}_${isFalsey(option.value)}_${index}`"
                :value="option.value"
                :disabled="option.disabled ? option.disabled : false">
                {{ option.text }}
            </option>
        </select>
        <label
            :for="id"
            class="lni-c-select__label">
            {{ labelText }}
        </label>
        <span
            class="lni-c-select__icon lnicon--triangle--down"
            aria-hidden="true"></span>
        <div class="lni-c-select__indicator lni-u-full-width"></div>
        <div
            v-if="hasInlineMessages && ( hasHelperText || hasErrorText)"
            :id="`${id}_helper-text`"
            :class="hasErrorText ? 'lni-c-select__error-message' : null"
            class="lni-c-select__helper-text lni-u-type--xxs lni-u-line-height--tight"
            aria-live="polite">
            <!-- space holder for error messages even if empty -->
            <template v-if="hasErrorText">
                <p>{{ errorText }}</p>
            </template>
            <template v-else>
                <slot name="helperText"></slot>
            </template>
        </div>
    </div>
</template>

<script>/**
 *  The lni-select component.
 *  @module components/lni-select
 */


/**
 * The lni-select is a basic select list.
 */

export default {
    name: 'lni-select',
    computed: {
        describedBy() {
            let ids = '';
            if (this.ariaDescribedby) {
                ids += `${this.ariaDescribedby}`;
            }

            if (this.hasHelperText || this.hasErrorText) {
                ids += `${ids.length > 0 ? ' ' : ''}${this.id}_helper-text`;
            }

            if (!ids.length) {
                return false;
            }

            return ids;
        },
        hasErrorText() {
            return !!this.$store.state[this.id].errorText;
        },
        hasHelperText() {
            return !!this.$slots.helperText;
        },
        hasSelection() {
            return !!(this.value || this.placeholder);
        },
    },
    mounted() {
        this.updateValidity();

        this.$watch('value', () => {
            this.setValueOnInput();
            this.updateValidity();
        });

        this.$watch('options', () => {
            this.setValueOnInput();
        });
    },
    methods: {
        setValueOnInput() {
            this.$refs.select.value = this.value;
        },
        updateValidity() {
            const el = this.$refs.select;
            const errorMessage = this.messages.valueMissing.inline(this.$store.state);
            if (this.required) {
                if (el.validity.valueMissing) {
                    el.setCustomValidity(errorMessage);
                } else {
                    el.setCustomValidity('');
                }
            }

            return this.$store.dispatch(`updateValidity`, {
                targetId: this.id,
                validity: el.validity,
            });
        },
        onChange(event) {
            this.value = event.target.value;
            this.$nextTick(() => {
                this.$store.commit(`${this.id}/setDirty`, {
                    value: true,
                });
                this.updateValidity().then(() => {
                    if (this.$store.state[this.id].flags.dirty && this.changeValidationAction) {
                        this.dispatchEvent('changeValidationAction');
                    }
                });

                this.dispatchEvent('changeAction').then(() => {
                    this.$emit('change');
                });
            });
        },
        isFalsey(optionValue) {
            switch (optionValue) {
                case '':
                    return 'empty';
                case null:
                    return 'null';
                case undefined:
                    return 'undefined';
                default:
                    return optionValue;
            }
        },
    },
}; </script>