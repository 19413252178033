import TableCaption from '@gov.wa.lni/component.lni-table/components/caption.js';
import TableHeader from '@gov.wa.lni/component.lni-table/components/header.js';
import TableBody from '@gov.wa.lni/component.lni-table/components/body.js';
import TableMessage from '@gov.wa.lni/component.lni-table/components/message.js';

export default {
    TableCaption,
    TableHeader,
    TableBody,
    TableMessage,
};