import actions from '@gov.wa.lni/component.lni-error-container/actions';

export default () => ({
    state: {
        lang: '',
        heading: '',
        message: '',
        customMessages: null,
        visible: false,
        isErrorContainer: true,
        errorsCollectedFrom: [],
        errors: null,
        scrollToErrorsList: true,
    },
    actions: {
        ...actions,
    },
});